import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

const FileLoaderScreen = ({ projectFileLoadingProgress }) => {
  return projectFileLoadingProgress.isLoading ? (
    <div
      style={{
        zIndex: 9999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(40, 41, 51, 0.85)",
        position: "absolute",
        top: 0,
        left: 0,
        height: "100vh",
        width: "100vw",
      }}
    >
      <div style={{ margin: "0 auto", width: "60%" }}>
        <ProgressBar
          width="100%"
          percent={projectFileLoadingProgress.progress}
          filledBackground="#0d6efd"
        >
          <Step transition="scale">
            {({ accomplished }) => (
              <div
                style={{
                  color: accomplished ? "white" : "black",
                  backgroundColor: accomplished ? "#0d6efd" : "white",
                  height: 30,
                  width: 30,
                  lineHeight: "30px",
                  textAlign: "center",
                }}
                class="rounded-circle"
              >
                1
              </div>
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <div
                style={{
                  color: accomplished ? "white" : "black",
                  backgroundColor: accomplished ? "#0d6efd" : "white",
                  height: 30,
                  width: 30,
                  lineHeight: "30px",
                  textAlign: "center",
                }}
                class="rounded-circle"
              >
                2
              </div>
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <div
                style={{
                  color: accomplished ? "white" : "black",
                  backgroundColor: accomplished ? "#0d6efd" : "white",
                  height: 30,
                  width: 30,
                  lineHeight: "30px",
                  textAlign: "center",
                }}
                class="rounded-circle"
              >
                3
              </div>
            )}
          </Step>
        </ProgressBar>
      </div>
    </div>
  ) : null;
};

export default FileLoaderScreen;
