const LockScreen = ({ isLoding }) => {
  return isLoding ? (
    <div
      style={{
        zIndex: 9999,
        backdropFilter: "blur(2px)",
        position: "absolute",
        top: 0,
        left: 0,
        height: "100vh",
        width: "100vw",
      }}
    ></div>
  ) : null;
};

export default LockScreen;
