import axios from "axios";

const baseURL = process.env.REACT_APP_MAIN_BE_URL;
const ApiURL = baseURL + "/api";

const Main = axios.create({
  baseURL: ApiURL,
});

const MainAuth = axios.create({
  baseURL: ApiURL,
});

MainAuth.interceptors.request.use((config) => {
  config.headers["Authorization"] = `Bearer ${sessionStorage.getItem("token")}`;
  return config;
});

const instance = {
  Main,
  MainAuth,
};

export default instance;
