import { Modal, Button } from "react-bootstrap";

const YesNoModal = ({ title, text, onYes, onNo, show }) => {
  return (
    <Modal show={show} onHide={onNo}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{text}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onNo}>
          Hayır
        </Button>
        <Button variant="primary" onClick={onYes}>
          Evet
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default YesNoModal;
