import _ from "lodash";
import { Modal, Button, Form, Col, Row } from "react-bootstrap";
import AppConfig from "../../../../config";
import { toast } from "react-toastify";

const ShiftCustomersBetweenAreasModal = ({ areas, state, setState }) => {
  const onAddArea = ({ areaId }) => {
    let areaIds = _.cloneDeep(state.areaIds);
    if (areaIds.includes(areaId)) {
      areaIds = areaIds.filter(function (item) {
        return item !== areaId;
      });
    } else {
      areaIds.push(areaId);
    }
    setState({
      ...state,
      areaIds,
    });
  };
  const onSelectSpeed = ({ speed }) => {
    setState({
      ...state,
      speed,
    });
  };
  const onLookCorners = ({ lookCorners }) => {
    setState({
      ...state,
      lookCorners,
    });
  };
  const onFixFeasibility = ({ fixFeasibility }) => {
    setState({
      ...state,
      fixFeasibility,
    });
  };
  const onCheckFeasibility = ({ checkFeasibility }) => {
    setState({
      ...state,
      checkFeasibility,
    });
  };
  const onCheckUtilization = ({ checkUtilization }) => {
    setState({
      ...state,
      checkUtilization,
    });
  };
  const onCheckCorners = ({ checkCorners }) => {
    setState({
      ...state,
      checkCorners,
    });
  };
  const onCheckDistance = ({ checkDistance }) => {
    setState({
      ...state,
      checkDistance,
    });
  };
  const onHide = () => {
    setState({
      show: false,
      areaIds: [],
      fixFeasibility: false,
      speed: 0,
      lookCorners: false,
      checkFeasibility: false,
      checkUtilization: false,
      checkCorners: false,
    });
  };

  const onStart = async ({
    areaIds,
    fixFeasibility,
    speed,
    lookCorners,
    checkCorners,
    checkFeasibility,
    checkUtilization,
    checkDistance,
  }) => {
    const requestObject = {
      area_ids: areaIds,
      fix_feasibility: fixFeasibility,
      speed,
      look_corners: lookCorners,
      check_feasibility: checkFeasibility,
      check_utilization: checkUtilization,
      check_corners: checkCorners,
      check_distance: checkDistance,
    };
    try {
      await AppConfig.axiosInstances.MainAuth.post(
        `/areas/optimize/areadayswap`,
        requestObject
      );
      toast.success(
        "Bölgeler arası müşteri değişimi başladı, lütfen bekleyin."
      );
      setState({ ...state, show: false });
    } catch (e) {
      if (e.response?.status === 503) {
        toast.error(
          "Sunucuda aktif işlem var, lütfen biraz sonra tekrar deneyin."
        );
      } else {
        toast.error(
          "Bölgeler arası müşteri değişimi işlemi sırasında bir hata oluştu. Lütfen daha sonra tekrar deneyin."
        );
      }
    }
  };

  return (
    <Modal show={state.show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Bölgeler Arası Müşteri Kaydır</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3 mt-3" as={Col}>
          <Form.Label row>Bölgeleri Seç</Form.Label>
          {Object.keys(areas).map((i) => {
            const item = areas[i];
            return (
              <div
                className="d-flex justify-content-between align-items-end"
                key={item.id + ShiftCustomersBetweenAreasModal.name}
              >
                <Form.Check
                  type="checkbox"
                  label={item.name}
                  size="sm"
                  onClick={() =>
                    onAddArea({
                      areaId: item.id,
                    })
                  }
                  style={{ marginRight: 5 }}
                />
              </div>
            );
          })}
        </Form.Group>
        <Row className="mb-3 mt-3">
          <Form.Group as={Col}>
            <Form.Label column>Hız</Form.Label>
            <Form.Select
              aria-label="Hız"
              onChange={(e) => {
                onSelectSpeed({ speed: Number(e.target.value) });
              }}
            >
              <option>Hız Seçiniz</option>
              <option value="0">Hızlı</option>
              <option value="1">Orta Hızlı</option>
              <option value="2">Yavaş</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="d-flex align-items-end" as={Col}>
            <Form.Check
              type="checkbox"
              label="Feasibility Çöz"
              size="sm"
              checked={state.fixFeasibility}
              onChange={(e) => {
                onFixFeasibility({
                  fixFeasibility: !state.fixFeasibility,
                });
              }}
              style={{ marginRight: 5 }}
            />
          </Form.Group>
          <Form.Group className="d-flex align-items-end" as={Col}>
            <Form.Check
              type="checkbox"
              label="Köşelere Bak"
              size="sm"
              checked={state.lookCorners}
              onChange={(e) => {
                onLookCorners({
                  lookCorners: !state.lookCorners,
                });
              }}
              style={{ marginRight: 5 }}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3 mt-3">
          <Form.Group className="d-flex align-items-end" as={Col}>
            <Form.Check
              type="checkbox"
              label="Feasibility"
              size="sm"
              checked={state.checkFeasibility}
              onChange={(e) => {
                onCheckFeasibility({
                  fixFeasibility: !state.checkFeasibility,
                });
              }}
              style={{ marginRight: 5 }}
            />
          </Form.Group>
          <Form.Group className="d-flex align-items-end" as={Col}>
            <Form.Check
              type="checkbox"
              label="Utilization"
              size="sm"
              checked={state.checkUtilization}
              onChange={(e) => {
                onCheckUtilization({
                  checkUtilization: !state.checkUtilization,
                });
              }}
              style={{ marginRight: 5 }}
            />
          </Form.Group>
          <Form.Group className="d-flex align-items-end" as={Col}>
            <Form.Check
              type="checkbox"
              label="Corners"
              size="sm"
              checked={state.checkCorners}
              onChange={(e) => {
                onCheckCorners({
                  checkCorners: !state.checkCorners,
                });
              }}
              style={{ marginRight: 5 }}
            />
          </Form.Group>
          <Form.Group className="d-flex align-items-end" as={Col}>
            <Form.Check
              type="checkbox"
              label="Distance"
              size="sm"
              checked={state.checkDistance}
              onChange={(e) => {
                onCheckDistance({
                  checkDistance: !state.checkDistance,
                });
              }}
              style={{ marginRight: 5 }}
            />
          </Form.Group>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => {
            onStart({
              areaIds: state.areaIds,
              fixFeasibility: state.fixFeasibility,
              speed: state.speed,
              lookCorners: state.lookCorners,
              checkFeasibility: state.checkFeasibility,
              checkUtilization: state.checkUtilization,
              checkCorners: state.checkCorners,
              checkDistance: state.checkDistance,
            });
          }}
        >
          İşlemi Başlat
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShiftCustomersBetweenAreasModal;
