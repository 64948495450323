import "./index.scss";

const ChromeWarn = () => {
  return (
    <div className="chrome-warn">
      <div className="chrome-warn__content">
        <h1 className="chrome-warn__title">
          Lütfen Chrome Tarayıcısını Kullanın
        </h1>
        <p className="chrome-warn__text">
          Bu uygulama en iyi performans için sadece Chrome tarayıcısında
          çalışmaktadır. Diğer tarayıcılarla çalıştırıldığında bazı özellikler
          ve sayfalar düzgün çalışmayabilir.
        </p>
      </div>
    </div>
  );
};

export default ChromeWarn;
