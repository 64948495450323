import { Modal, Button, Form, Col, Row, Spinner } from "react-bootstrap";
import AppConfig from "../../../../config";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

const OperatinalCostsModal = ({ show, onHide, onClickCta }) => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    fuel_cost: "Yükleniyor...",
    fuel_consumption_per_100_km: "Yükleniyor...",
    gross_salary: "Yükleniyor...",
    vehicle_depreciation_cost_per_km: "Yükleniyor...",
    co2_emission_per_km: "Yükleniyor...",
    vehicle_maintenance_cost: "Yükleniyor...",
  });

  const [isDisabled, setIsDisabled] = useState(true);

  const getParams = async () => {
    try {
      const response = await AppConfig.axiosInstances.MainAuth.get(
        `/parameters/operationalcosts`
      );
      setState({
        fuel_cost: response.data.fuel_cost,
        fuel_consumption_per_100_km: response.data.fuel_consumption_per_100_km,
        gross_salary: response.data.gross_salary,
        vehicle_depreciation_cost_per_km:
          response.data.vehicle_depreciation_cost_per_km,
        co2_emission_per_km: response.data.co2_emission_per_km,
        vehicle_maintenance_cost: response.data.vehicle_maintenance_cost,
      });
    } catch (e) {
      toast.error("Maliyet parametreleri getirmede hata oluştu");
    }
  };

  useEffect(() => {
    if (show) {
      getParams();
    }
  }, [show]);

  const onClose = () => {
    onHide();
    setState({
      fuel_cost: "Yükleniyor...",
      fuel_consumption_per_100_km: "Yükleniyor...",
      gross_salary: "Yükleniyor...",
      vehicle_depreciation_cost_per_km: "Yükleniyor...",
      co2_emission_per_km: "Yükleniyor...",
      vehicle_maintenance_cost: "Yükleniyor...",
    });
  };

  const onSubmit = async () => {
    const requestObject = {
      fuel_cost: Number(state.fuel_cost),
      fuel_consumption_per_100_km: Number(state.fuel_consumption_per_100_km),
      gross_salary: Number(state.gross_salary),
      vehicle_depreciation_cost_per_km: Number(
        state.vehicle_depreciation_cost_per_km
      ),
      co2_emission_per_km: Number(state.co2_emission_per_km),
      vehicle_maintenance_cost: Number(state.vehicle_maintenance_cost),
    };
    try {
      setLoading(true);
      await AppConfig.axiosInstances.MainAuth.post(
        `/parameters/operationalcosts`,
        requestObject
      );
      await onClickCta();
      toast.success("Maliyet parametreleri başarıyla güncellendi");
    } catch (e) {
      if (e.response?.status === 503) {
        toast.error(
          "Sunucuda aktif işlem var, lütfen biraz sonra tekrar deneyin."
        );
      } else {
        toast.error("Parametreleri girmede bir hata oluştu");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setIsDisabled(
      loading ||
        state.fuel_cost === "" ||
        state.fuel_consumption_per_100_km === "" ||
        state.gross_salary === "" ||
        state.vehicle_depreciation_cost_per_km === "" ||
        state.co2_emission_per_km === "" ||
        state.vehicle_maintenance_cost === ""
    );
  }, [loading, state]);

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Maliyet Hesaplama Parametreleri Gir</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mt-3 mb-3">
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="7">
              Yakıt Maliyeti(LT)
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="number"
                min="0"
                onChange={(e) =>
                  setState({ ...state, fuel_cost: e.target.value })
                }
                value={state.fuel_cost}
              />
            </Col>
          </Form.Group>
        </Row>
        <Row className="mt-3 mb-3">
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="7">
              Yakıt Tüketimi(LT / 100 KM)
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="number"
                min="0"
                onChange={(e) =>
                  setState({
                    ...state,
                    fuel_consumption_per_100_km: e.target.value,
                  })
                }
                value={state.fuel_consumption_per_100_km}
              />
            </Col>
          </Form.Group>
        </Row>
        <Row className="mt-3 mb-3">
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="7">
              Personel Aylık Brüt Maliyeti
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="number"
                min="0"
                onChange={(e) =>
                  setState({
                    ...state,
                    gross_salary: e.target.value,
                  })
                }
                value={state.gross_salary}
              />
            </Col>
          </Form.Group>
        </Row>
        <Row className="mt-3 mb-3">
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="7">
              Araç Amortismanı(KM)
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="number"
                min="0"
                onChange={(e) =>
                  setState({
                    ...state,
                    vehicle_depreciation_cost_per_km: e.target.value,
                  })
                }
                value={state.vehicle_depreciation_cost_per_km}
              />
            </Col>
          </Form.Group>
        </Row>
        <Row className="mt-3 mb-3">
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="7">
              Co2 Emisyonu(KM)
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="number"
                min="0"
                onChange={(e) =>
                  setState({
                    ...state,
                    co2_emission_per_km: e.target.value,
                  })
                }
                value={state.co2_emission_per_km}
              />
            </Col>
          </Form.Group>
        </Row>
        <Row className="mt-3 mb-3">
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="7">
              Yıllık Araç Bakım Maliyeti
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="number"
                min="0"
                onChange={(e) =>
                  setState({
                    ...state,
                    vehicle_maintenance_cost: e.target.value,
                  })
                }
                value={state.vehicle_maintenance_cost}
              />
            </Col>
          </Form.Group>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={onSubmit}
          disabled={isDisabled}
          className="d-flex align-items-center"
        >
          {loading ? <Spinner animation="border" size="sm" /> : null}{" "}
          <span className="ml-2">Kaydet</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OperatinalCostsModal;
