import { ACTIONS } from "./actions";

const initialState = {
  loading: false,
  isLoggedIn: false,
  reloadAll: false,
  customers: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case ACTIONS.IS_LOGGEDIN: {
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    }
    case ACTIONS.SET_CUSTOMERS: {
      return {
        ...state,
        customers: action.payload,
      };
    }
    case ACTIONS.SET_RELOAD_ALL: {
      return {
        ...state,
        reloadAll: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
