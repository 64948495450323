import { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import AppConfig from "../../../config";

const AddNewAreaModal = ({ item, onClose }) => {
  const [title, setTitle] = useState("");

  const onSubmit = async () => {
    try {
      if (!title) toast.error("Lütfen bölge ismi giriniz.");
      await AppConfig.axiosInstances.MainAuth.post(`/areas`, {
        title,
      });
      toast.success(`${title} bölgesi eklendi.`);
      onClose();
      setTitle("");
    } catch (error) {
      toast.error("Bölge eklemede hata oluştu.");
    }
  };

  return (
    <Modal show={item.show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Yeni Bölge Ekle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Label>Bölge İsmi</Form.Label>
          <Form.Control
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onSubmit}>
          Ekle
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewAreaModal;
