import React, { useState } from "react";
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AppConfig from "../../config";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const navigate = useNavigate();

  const onLogin = async () => {
    try {
      setLoading(true);
      const res = await AppConfig.axiosInstances.Main.post(`/token/`, {
        username,
        password,
      });
      const token = res.data.access;
      sessionStorage.setItem("token", token);
      setTimeout(() => {
        navigate("/");
        setLoading(false);
      }, 500);
    } catch (error) {
      if (error?.response?.status === 401)
        toast.error("Kullanıcı adı veya şifre hatalı");
      else {
        toast.error("Bir hata oluştu, kod: 10");
      }
      setLoading(false);
    }
  };

  const loginSubmit = (e) => {
    e.preventDefault();
    onLogin();
  };

  return (
    <div>
      <Container>
        <Row className="vh-100 d-flex justify-content-center align-items-center">
          <Col md={8} lg={6} xs={12}>
            <div className="border border-3 border-primary"></div>
            <Card className="shadow">
              <Card.Body>
                <div className="mb-3 mt-md-4">
                  <h2 className="fw-bold mb-2">
                    {process.env.REACT_APP_LOGO_TEXT}
                  </h2>
                  <p className=" mb-5">Lütfen email ve şifrenizi giriniz.</p>
                  <div className="mb-3">
                    <Form onSubmit={loginSubmit} noValidate>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="text-center">
                          Kullanıcı Adı
                        </Form.Label>
                        <Form.Control
                          type="text"
                          onChange={(e) => setUsername(e.target.value)}
                          value={username}
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Şifre</Form.Label>
                        <Form.Control
                          type="password"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                        />
                      </Form.Group>
                      <div className="d-grid">
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={loading}
                        >
                          {loading && (
                            <i
                              className="fa-solid fa-spinner fa-spin"
                              style={{ marginRight: 6 }}
                            ></i>
                          )}
                          {loading ? "Giriş Yapılıyor..." : "Giriş"}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Login;
