import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";

import { toast } from "react-toastify";
import AppServices from "../../services";

const UnprotectedRoute = ({ children }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        setLoading(true);
        await AppServices.isAuthenticated();
        dispatch({ type: "IS_LOGGEDIN", payload: true });
        setIsAuth(true);
      } catch (e) {
        setIsAuth(false);
        dispatch({ type: "IS_LOGGEDIN", payload: false });
        if (
          e instanceof Error &&
          e.message !== "Unauthorized" &&
          e.message !== "Unauthenticated" &&
          !e.message.includes("403") &&
          !e.message.includes("401")
        ) {
          toast.error(e.message);
        }
      } finally {
        setLoading(false);
      }
    };
    checkAuth();
  }, [dispatch]);

  if (loading) {
    return null;
  }

  if (isAuth) {
    return <Navigate to="/" replace />;
  }
  return <> {children} </>;
};

export default UnprotectedRoute;
