import "./index.scss";

import { useEffect, useState } from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AppServices from "../../../../../services";
import { toast } from "react-toastify";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const AreaDaysDragDrop = ({
  items,
  open,
  onClose,
  areaName,
  areaId,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const [calculateLoading, setCalculateLoading] = useState(false);
  const [, setLastChangedIndex] = useState(null);
  const [reorderedItems, setReorderedItems] = useState([]);
  const [costChanges, setCostChanges] = useState(null);
  const [itemsState, setItemsState] = useState([]);
  const reloadAll = useSelector((state) => state.reloadAll);
  const dispatch = useDispatch();

  const reload = async () => {
    try {
      setCostChanges(null);
      await onSuccess();
      dispatch({ type: "SET_RELOAD_ALL", payload: false });
    } catch (e) {
      toast.error(e.message);
    }
  };

  useEffect(() => {
    if (reloadAll) {
      reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadAll]);

  useEffect(() => {
    const clonedItems = _.cloneDeep(items);
    const newItems = clonedItems.map((item) => {
      item.customers = item.customers.filter((customer) => {
        return !customer.name.toLowerCase().includes("depo");
      });
      return item;
    });
    setItemsState(newItems);
  }, [items]);

  useEffect(() => {
    const clonedItems = _.cloneDeep(items);
    const newItems = clonedItems.map((item) => {
      item.customers = item.customers.filter((customer) => {
        return !customer.name.toLowerCase().includes("depo");
      });
      return item;
    });
    setReorderedItems(newItems);
  }, [items]);

  useEffect(() => {
    if (!open) {
      const clonedItems = _.cloneDeep(items);
      const newItems = clonedItems.map((item) => {
        item.customers = item.customers.filter((customer) => {
          return !customer.name.toLowerCase().includes("depo");
        });
        return item;
      });
      setReorderedItems(newItems);
      // setDragDropUsed(false);
      setLastChangedIndex(null);
      setCostChanges(null);
    }
  }, [items, open]);

  const handleDrop = async (droppedItem) => {
    const { source, destination } = droppedItem;
    if (!destination) return;
    const updatedList = _.cloneDeep(reorderedItems);
    const movedFromDay = updatedList.find(
      (day) => day.name === source.droppableId
    );
    const movedToDay = updatedList.find(
      (day) => day.name === destination.droppableId
    );
    if (movedFromDay.id !== movedToDay.id) {
      const movedFromDayIndex = source.index;
      const movedToDayIndex = destination.index;
      movedToDay.customers.splice(
        movedToDayIndex,
        0,
        movedFromDay.customers[movedFromDayIndex]
      );
      movedFromDay.customers = movedFromDay.customers.filter(
        (c, i) => i !== movedFromDayIndex
      );
      updatedList[movedFromDay.id - 1] = movedFromDay;
      updatedList[movedToDay.id - 1] = movedToDay;
      // setDragDropUsed(!_.isEqual(itemsState, updatedList));
      setReorderedItems(updatedList);
    } else {
      const movedFromDayIndex = source.index;
      const movedToDayIndex = destination.index;
      const movedCustomer = movedFromDay.customers[movedFromDayIndex];
      movedFromDay.customers.splice(movedFromDayIndex, 1);
      movedFromDay.customers.splice(movedToDayIndex, 0, movedCustomer);
      updatedList[movedFromDay.id - 1] = movedFromDay;
      // setDragDropUsed(!_.isEqual(itemsState, updatedList));
      setReorderedItems(updatedList);
    }
  };

  const onClickApplyChanges = async () => {
    try {
      setLoading(true);
      const clonedItems = _.cloneDeep(reorderedItems);
      const customerIds = clonedItems.map((item) => {
        const theitem = {
          day: item.id - 1,
          sequenceToApply: item.customers.map((customer) => customer.id),
        };
        return theitem;
      });
      await AppServices.changeCustomersOrderInAreaDays({
        items: customerIds,
        areaId: areaId,
      });
      toast.success("İstek alındı, lütfen bekleyin...");
    } catch (e) {
      toast.error("Bir hata oluştu, kod: 8");
    } finally {
      setLoading(false);
    }
  };

  const onClickCalculateChanges = async () => {
    try {
      setCalculateLoading(true);
      const clonedItems = _.cloneDeep(reorderedItems);
      const customerIds = clonedItems.map((item) => {
        const theitem = {
          day: item.id - 1,
          sequenceToApply: item.customers.map((customer) => customer.id),
        };
        return theitem;
      });
      const res = await AppServices.calculateCustomerOrderChangeInAreaDays({
        items: customerIds,
        areaId: areaId,
      });
      setCostChanges(res);
    } catch (e) {
      toast.error("Bir hata oluştu, kod: 9");
    } finally {
      setCalculateLoading(false);
    }
  };

  const onClickReset = () => {
    setReorderedItems(itemsState);
    // setDragDropUsed(false);
    setLastChangedIndex(null);
    // setCostChanges(null);
    onClickCalculateChanges();
  };

  return (
    <Modal
      show={open}
      onHide={onClose}
      backdrop="static"
      dialogClassName="modal-1280"
    >
      <Modal.Header closeButton>
        <Modal.Title>{areaName} Bölgesi Ziyaret Günleri Düzenle</Modal.Title>
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip>
              Sürükle bırak ile günler arası müşterileri taşıyabilirsiniz.
            </Tooltip>
          }
        >
          <div>
            <i
              className="fa-solid fa-circle-info fa-lg text-black"
              style={{ marginLeft: 10 }}
            ></i>
          </div>
        </OverlayTrigger>
      </Modal.Header>
      <Modal.Body style={{ position: "relative", height: 700 }}>
        <div className="daysdrag-container">
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ marginTop: 15, marginLeft: 15, marginRight: 15 }}
          >
            <Button
              variant="primary"
              size="sm"
              onClick={onClickApplyChanges}
              disabled={loading} //{!dragDropUsed || loading}
            >
              {loading ? "Uygulanıyor... " : "Uygula "}
              {loading ? (
                <i
                  className="fa-solid fa-spinner fa-spin"
                  style={{ marginLeft: 4 }}
                />
              ) : (
                <i
                  className="fa-solid fa-arrow-up-right-from-square fa-xs"
                  style={{ marginLeft: 4 }}
                ></i>
              )}
            </Button>
            <Button
              variant="primary"
              onClick={onClickCalculateChanges}
              disabled={calculateLoading} //{!dragDropUsed || calculateLoading}
              size="sm"
            >
              {calculateLoading ? "Hesaplanıyor... " : "Hesapla "}
              {calculateLoading ? (
                <i
                  className="fa-solid fa-spinner fa-spin"
                  style={{ marginLeft: 4 }}
                />
              ) : (
                <i
                  className="fa-solid fa-calculator fa-xs"
                  style={{ marginLeft: 4 }}
                ></i>
              )}
            </Button>
            <Button
              variant="primary"
              onClick={onClickReset}
              disabled={false} //{!dragDropUsed}
              size="sm"
            >
              Sıfırla
              <i
                className="fa-solid fa-clock-rotate-left"
                style={{ marginLeft: 4 }}
              ></i>
            </Button>
          </div>
          <div
            className="d-flex justify-content-end align-items-center flex-row"
            style={{ marginTop: 15, marginLeft: 15, marginRight: 15 }}
          >
            <div
              className="d-flex align-items-center justify-content-between w-100"
              style={{ color: "white", fontSize: 12 }}
            >
              {costChanges?.distance ? (
                <div className="d-flex align-items-center">
                  Mesafe: {costChanges?.distance?.toFixed(2)}
                  {"km "}
                  <div>
                    {Number(costChanges.delta_distance.toFixed(2)) > 0 ? (
                      <div
                        className="costchange-positive"
                        style={{ marginLeft: "1ch" }}
                      >
                        + {costChanges.delta_distance.toFixed(2)} km
                      </div>
                    ) : null}
                    {Number(costChanges.delta_distance.toFixed(2)) < 0 ? (
                      <div
                        className="costchange-negative"
                        style={{ marginLeft: "1ch" }}
                      >
                        {costChanges.delta_distance.toFixed(2)} km
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
              {costChanges?.delta_util ? (
                <div className="d-flex align-items-center">
                  Verimlilik:{" "}
                  <div>
                    {Number(costChanges.delta_util.toFixed(2)) > 0 ? (
                      <div className="costchange-positive">
                        % {costChanges.delta_util.toFixed(2)}
                      </div>
                    ) : null}
                    {Number(costChanges.delta_util.toFixed(2)) < 0 ? (
                      <div className="costchange-negative">
                        %{costChanges.delta_util.toFixed(2)}
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <DragDropContext onDragEnd={handleDrop}>
            <div className="daysdrag-items-container">
              {reorderedItems
                .filter((d) => d.name !== "Pazar")
                .map((d) => {
                  const day = d.name;
                  const customers = d.customers;
                  return (
                    <div
                      className="daysdrag-item-container"
                      key={JSON.stringify(d)}
                    >
                      <div className="daysdrag-title">
                        {day} - {customers.length}
                      </div>
                      <Droppable droppableId={day} isDropDisabled={false}>
                        {(provided) => (
                          <div
                            className="daysdrag-items"
                            ref={provided.innerRef}
                          >
                            {customers.map((c, i) => {
                              return (
                                <Draggable
                                  key={`${day}${c.name}${c.id}`}
                                  draggableId={`${day}${c.name}${c.id}`}
                                  index={i}
                                >
                                  {(providedd) => (
                                    <div
                                      {...providedd.dragHandleProps}
                                      {...providedd.draggableProps}
                                      ref={providedd.innerRef}
                                      className="daydrags-drag-item"
                                    >
                                      <p>
                                        {i + 1} - {c.name}
                                      </p>
                                      <div>
                                        Frekans: <b>{c.frequency}</b>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  );
                })}
            </div>
          </DragDropContext>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AreaDaysDragDrop;
