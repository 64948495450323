import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Login from "../pages/login";
import Dashboard from "../pages/dashboard";
import UnprotectedRoute from "../components/unprotected-route";
import ProtectedRoute from "../components/protected-route";
import ChromeWarn from "../components/chrome-warn";

const App = () => {
  const [loading, setLoading] = useState(true);
  const [isChrome, setIsChrome] = useState(false);

  useEffect(() => {
    function fnBrowserDetect() {
      let userAgent = navigator.userAgent;
      if (userAgent.match(/chrome|chromium|crios/i)) {
        setIsChrome(true);
      }
      setLoading(false);
    }
    fnBrowserDetect();
  }, []);
  return (
    <Router>
      <ToastContainer style={{zIndex: 99999}} position="top-right" theme="colored" />
      {!loading && !isChrome && <ChromeWarn />}
      {!loading && isChrome && (
        <Routes>
          <Route
            path="/login"
            element={
              <UnprotectedRoute>
                <Login />
              </UnprotectedRoute>
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      )}
    </Router>
  );
};

export default App;
