import _ from "lodash";
import { Modal, Button, Form, Col, Spinner } from "react-bootstrap";
import AppConfig from "../../../../config";
import { toast } from "react-toastify";
import { useState } from "react";

const SwapCustomersBetweenDaysModal = ({ show, areas, onClose }) => {
  const [state, setState] = useState({
    areaIds: [],
  });
  const [loading, setLoading] = useState(false);

  const onAddArea = ({ areaId }) => {
    let areaIds = _.cloneDeep(state.areaIds);
    if (areaIds.includes(areaId)) {
      areaIds = areaIds.filter(function (item) {
        return item !== areaId;
      });
    } else {
      areaIds.push(areaId);
    }
    setState({
      ...state,
      areaIds,
    });
  };

  const onHide = () => {
    onClose();
    setTimeout(() => {
      setState({
        areaIds: [],
      });
    }, 200);
  };

  const onStart = async () => {
    const requestObject = {
      area_ids: state.areaIds,
    };
    try {
      setLoading(true);
      await AppConfig.axiosInstances.MainAuth.post(
        `/areas/optimize/withinrouteswap`,
        requestObject
      );
      toast.success(
        "Bölgeler arası müşteri değişimi başladı, lütfen bekleyin."
      );
      onHide();
    } catch (e) {
      if (e.response?.status === 503) {
        toast.error(
          "Sunucuda aktif işlem var, lütfen biraz sonra tekrar deneyin."
        );
      } else {
        toast.error(
          "Bölge içerisinde gün kaydırma işlemi sırasında bir hata oluştu. Lütfen daha sonra tekrar deneyin."
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Bölge İçerisinde Gün Kaydır</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3 mt-3" as={Col}>
          <Form.Label row>Bölgeleri Seç</Form.Label>
          {Object.keys(areas).map((i) => {
            const item = areas[i];
            return (
              <div
                className="d-flex justify-content-between align-items-end"
                key={item.id + SwapCustomersBetweenDaysModal.name}
              >
                <Form.Check
                  type="checkbox"
                  label={item.name}
                  size="sm"
                  onClick={() =>
                    onAddArea({
                      areaId: item.id,
                    })
                  }
                  style={{ marginRight: 5 }}
                />
              </div>
            );
          })}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onStart}>
          {loading ? <Spinner animation="border" size="sm" /> : null}{" "}
          <span className="ml-2">İşlemi Başlat</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SwapCustomersBetweenDaysModal;
