/* eslint-disable react-hooks/exhaustive-deps */
import "./index.scss";
import { useCallback, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import L from "leaflet";
import AppConfig from "../../../../config";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import AreaDayDragDropModal from "./area-day-drag-drop";
import AreaDaysDragDrop from "./area-days-drag-drop";
import Icons from "../../../../assets/icons";

const AreaDaysModal = ({ show, onClose, areaId, areaName }) => {
  const [mapState, setMapState] = useState(null);
  const [layers, setLayers] = useState(null);
  const [days, setDays] = useState([
    {
      id: 1,
      name: "Pazartesi",
      checked: true,
      customers: [],
      depo: null,
    },
    {
      id: 2,
      name: "Salı",
      checked: false,
      customers: [],
      depo: null,
    },
    {
      id: 3,
      name: "Çarşamba",
      checked: false,
      customers: [],
      depo: null,
    },
    {
      id: 4,
      name: "Perşembe",
      checked: false,
      customers: [],
      depo: null,
    },
    {
      id: 5,
      name: "Cuma",
      checked: false,
      customers: [],
      depo: null,
    },
    {
      id: 6,
      name: "Cumartesi",
      checked: false,
      customers: [],
      depo: null,
    },
  ]);
  const [firstMapPoint, setFirstMapPoint] = useState(null);
  const [areaDayDragDropModal, setAreaDayDragDropModal] = useState({
    show: false,
    day: null,
    depo: null,
    customers: [],
  });
  const [areaDaysDragDropModal, setAreaDaysDragDropModal] = useState({
    show: false,
  });

  const handleCheck = (id, checked) => {
    setDays((prev) => {
      const newDays = prev.map((day) => {
        if (day.id === id) {
          return {
            ...day,
            checked: !checked,
          };
        }
        return day;
      });
      if (checked) {
        mapState.removeLayer(layers[id - 1]);
      } else {
        mapState.addLayer(layers[id - 1]);
      }
      return newDays;
    });
  };

  const getArea = useCallback(async () => {
    if (!areaId || areaId === null || areaId === undefined || areaId === "") {
      return;
    }
    const areaRes = await AppConfig.axiosInstances.MainAuth.get(
      `/areas/${areaId}`
    );
    const area = areaRes.data;
    const customersInMondayy = [];
    const customersInTuesdayy = [];
    const customersInWednesdayy = [];
    const customersInThursdayy = [];
    const customersInFridayy = [];
    const customersInSaturdayy = [];
    const customersInSundayy = [];

    const customers = area.customers;
    const areaName = area.name;
    let depo = area.area?.depo;
    // const depoAsCustomer = {
    //   id: depo.id,
    //   name: depo.customer_name,
    //   area: areaName,
    //   areaId: depo.id,
    //   lat: depo.lat,
    //   lng: depo.lng,
    // };
    if (depo) {
      setFirstMapPoint(depo);
      // customersInMondayy.push(depoAsCustomer);
      // customersInTuesdayy.push(depoAsCustomer);
      // customersInWednesdayy.push(depoAsCustomer);
      // customersInThursdayy.push(depoAsCustomer);
      // customersInFridayy.push(depoAsCustomer);
      // customersInSaturdayy.push(depoAsCustomer);
    }
    for (const customer of customers) {
      let frequency = 0;
      if (customer.visitDay0) frequency++;
      if (customer.visitDay1) frequency++;
      if (customer.visitDay2) frequency++;
      if (customer.visitDay3) frequency++;
      if (customer.visitDay4) frequency++;
      if (customer.visitDay5) frequency++;
      if (customer.visitDay6) frequency++;

      if (!depo) {
        depo = {
          id: customer.customer.id,
          name: customer.customer.customer_name,
          area: areaName,
          areaId: customer.area.id,
          lat: customer.customer.lat,
          lng: customer.customer.lng,
        };
        setFirstMapPoint({
          id: customer.customer.id,
          name: customer.customer.customer_name,
          area: areaName,
          areaId: customer.area.id,
          lat: customer.customer.lat,
          lng: customer.customer.lng,
        });
      }
      if (customer.visitDay0) {
        customersInMondayy.push({
          id: customer.customer.id,
          name: customer.customer.customer_name,
          area: areaName,
          areaId: customer.area.id,
          lat: customer.customer.lat,
          lng: customer.customer.lng,
          visitNo: customer.visitDay0,
          frequency,
        });
      }
      if (customer.visitDay1) {
        customersInTuesdayy.push({
          id: customer.customer.id,
          name: customer.customer.customer_name,
          area: areaName,
          areaId: area.id,
          lat: customer.customer.lat,
          lng: customer.customer.lng,
          visitNo: customer.visitDay1,
          frequency,
        });
      }
      if (customer.visitDay2) {
        customersInWednesdayy.push({
          id: customer.customer.id,
          name: customer.customer.customer_name,
          area: areaName,
          areaId: customer.area.id,
          lat: customer.customer.lat,
          lng: customer.customer.lng,
          visitNo: customer.visitDay2,
          frequency,
        });
      }
      if (customer.visitDay3) {
        customersInThursdayy.push({
          id: customer.customer.id,
          name: customer.customer.customer_name,
          area: areaName,
          areaId: customer.area.id,
          lat: customer.customer.lat,
          lng: customer.customer.lng,
          visitNo: customer.visitDay3,
          frequency,
        });
      }
      if (customer.visitDay4) {
        customersInFridayy.push({
          id: customer.customer.id,
          name: customer.customer.customer_name,
          area: areaName,
          areaId: customer.area.id,
          lat: customer.customer.lat,
          lng: customer.customer.lng,
          visitNo: customer.visitDay4,
          frequency,
        });
      }
      if (customer.visitDay5) {
        customersInSaturdayy.push({
          id: customer.customer.id,
          name: customer.customer.customer_name,
          area: areaName,
          areaId: customer.area.id,
          lat: customer.customer.lat,
          lng: customer.customer.lng,
          visitNo: customer.visitDay5,
          frequency,
        });
      }
      if (customer.visitDay6) {
        customersInSundayy.push({
          id: customer.customer.id,
          name: customer.customer.customer_name,
          area: areaName,
          areaId: customer.area.id,
          lat: customer.customer.lat,
          lng: customer.customer.lng,
          visitNo: customer.visitDay6,
          frequency,
        });
      }
    }

    customersInMondayy.sort((a, b) => a.visitNo - b.visitNo);
    customersInTuesdayy.sort((a, b) => a.visitNo - b.visitNo);
    customersInWednesdayy.sort((a, b) => a.visitNo - b.visitNo);
    customersInThursdayy.sort((a, b) => a.visitNo - b.visitNo);
    customersInFridayy.sort((a, b) => a.visitNo - b.visitNo);
    customersInSaturdayy.sort((a, b) => a.visitNo - b.visitNo);
    customersInSundayy.sort((a, b) => a.visitNo - b.visitNo);

    setDays([
      {
        id: 1,
        name: "Pazartesi",
        checked: true,
        customers: customersInMondayy,
        depo,
      },
      {
        id: 2,
        name: "Salı",
        checked: false,
        customers: customersInTuesdayy,
        depo,
      },
      {
        id: 3,
        name: "Çarşamba",
        checked: false,
        customers: customersInWednesdayy,
        depo,
      },
      {
        id: 4,
        name: "Perşembe",
        checked: false,
        customers: customersInThursdayy,
        depo,
      },
      {
        id: 5,
        name: "Cuma",
        checked: false,
        customers: customersInFridayy,
        depo,
      },
      {
        id: 6,
        name: "Cumartesi",
        checked: false,
        customers: customersInSaturdayy,
        depo,
      },
    ]);
  }, [areaId]);

  useEffect(() => {
    getArea();
  }, [getArea]);

  useEffect(() => {
    if (!mapState || !days[0].customers || !show) return;
    const layers = [];
    const fgMonday = L.featureGroup();

    for (const [index, c] of days[0].customers.entries()) {
      L.NumberedDivIcon = L.Icon.extend({
        options: {
          iconUrl: Icons.PurpleMarker,
          number: "",
          shadowUrl: null,
          iconSize: new L.Point(50, 50),
          iconAnchor: new L.Point(13, 41),
          popupAnchor: new L.Point(0, -33),
          className: "leaflet-div-icon",
        },
        createIcon() {
          const div = document.createElement("div");
          const img = this._createImg(this.options.iconUrl);
          const numdiv = document.createElement("div");
          numdiv.setAttribute("class", "number");
          numdiv.innerHTML = this.options.number || "";
          img.setAttribute("class", "location-pin-img");
          div.appendChild(img);
          div.appendChild(numdiv);
          this._setIconStyles(div, "icon");
          return div;
        },
        createShadow() {
          return null;
        },
      });
      const markerOptions = {
        clickable: true,
        draggable: false,
        icon: new L.NumberedDivIcon({ number: index + 1 }),
      };
      const marker = L.marker([c.lat, c.lng], markerOptions);

      if (days[0].customers[index + 1]) {
        const pointA = new L.LatLng(c.lat, c.lng);
        const pointB = new L.LatLng(
          days[0].customers[index + 1]?.lat,
          days[0].customers[index + 1]?.lng
        );
        const pointList = [pointA, pointB];
        const firstpolyline = new L.polyline(pointList, {
          color: "#4435C3",
          weight: 3,
          opacity: 1,
          smoothFactor: 1,
        });
        firstpolyline.addTo(fgMonday);
      }
      if (!days[0].customers[index + 1]) {
        const pointA = new L.LatLng(
          days[0].customers[0].lat,
          days[0].customers[0].lng
        );
        const pointB = new L.LatLng(
          days[0].customers[index]?.lat,
          days[0].customers[index]?.lng
        );
        const pointList = [pointA, pointB];
        const firstpolyline = new L.polyline(pointList, {
          color: "#4435C3",
          weight: 3,
          opacity: 1,
          smoothFactor: 1,
        });
        firstpolyline.addTo(fgMonday);
      }
      marker.bindPopup(`${index + 1} - ${c.name}`, {
        closeButton: true,
      });
      marker.addTo(fgMonday);
    }
    layers.push(fgMonday);

    const fgTuesday = L.featureGroup();
    for (const [index, c] of days[1].customers.entries()) {
      L.NumberedDivIcon = L.Icon.extend({
        options: {
          iconUrl: Icons.GreenMarker,
          number: "",
          shadowUrl: null,
          iconSize: new L.Point(50, 50),
          iconAnchor: new L.Point(13, 41),
          popupAnchor: new L.Point(0, -33),
          className: "leaflet-div-icon",
        },
        createIcon() {
          const div = document.createElement("div");
          const img = this._createImg(this.options.iconUrl);
          const numdiv = document.createElement("div");
          numdiv.setAttribute("class", "number");
          numdiv.innerHTML = this.options.number || "";
          img.setAttribute("class", "location-pin-img");
          div.appendChild(img);
          div.appendChild(numdiv);
          this._setIconStyles(div, "icon");
          return div;
        },
        createShadow() {
          return null;
        },
      });
      const markerOptions = {
        clickable: true,
        draggable: false,
        icon: new L.NumberedDivIcon({ number: index + 1 }),
      };
      const marker = L.marker([c.lat, c.lng], markerOptions);
      if (days[1].customers[index + 1]) {
        const pointA = new L.LatLng(c.lat, c.lng);
        const pointB = new L.LatLng(
          days[1].customers[index + 1]?.lat,
          days[1].customers[index + 1]?.lng
        );
        const pointList = [pointA, pointB];
        const firstpolyline = new L.polyline(pointList, {
          color: "#008000",
          weight: 3,
          opacity: 1,
          smoothFactor: 1,
        });
        firstpolyline.addTo(fgTuesday);
      }
      if (!days[1].customers[index + 1]) {
        const pointA = new L.LatLng(
          days[1].customers[0].lat,
          days[1].customers[0].lng
        );
        const pointB = new L.LatLng(
          days[1].customers[index]?.lat,
          days[1].customers[index]?.lng
        );
        const pointList = [pointA, pointB];
        const firstpolyline = new L.polyline(pointList, {
          color: "#008000",
          weight: 3,
          opacity: 1,
          smoothFactor: 1,
        });
        firstpolyline.addTo(fgTuesday);
      }
      marker.bindPopup(`${index + 1} - ${c.name}`, {
        closeButton: true,
      });
      marker.addTo(fgTuesday);
    }
    layers.push(fgTuesday);

    const fgWednesday = L.featureGroup();
    for (const [index, c] of days[2].customers.entries()) {
      L.NumberedDivIcon = L.Icon.extend({
        options: {
          iconUrl: Icons.BrownMarker,
          number: "",
          shadowUrl: null,
          iconSize: new L.Point(50, 50),
          iconAnchor: new L.Point(13, 41),
          popupAnchor: new L.Point(0, -33),
          className: "leaflet-div-icon",
        },
        createIcon() {
          const div = document.createElement("div");
          const img = this._createImg(this.options.iconUrl);
          const numdiv = document.createElement("div");
          numdiv.setAttribute("class", "number");
          numdiv.innerHTML = this.options.number || "";
          img.setAttribute("class", "location-pin-img");
          div.appendChild(img);
          div.appendChild(numdiv);
          this._setIconStyles(div, "icon");
          return div;
        },
        createShadow() {
          return null;
        },
      });
      const markerOptions = {
        clickable: true,
        draggable: false,
        icon: new L.NumberedDivIcon({ number: index + 1 }),
      };
      const marker = L.marker([c.lat, c.lng], markerOptions);
      if (days[2].customers[index + 1]) {
        const pointA = new L.LatLng(c.lat, c.lng);
        const pointB = new L.LatLng(
          days[2].customers[index + 1]?.lat,
          days[2].customers[index + 1]?.lng
        );
        const pointList = [pointA, pointB];
        const firstpolyline = new L.polyline(pointList, {
          color: "#723900",
          weight: 3,
          opacity: 1,
          smoothFactor: 1,
        });
        firstpolyline.addTo(fgWednesday);
      }
      if (!days[2].customers[index + 1]) {
        const pointA = new L.LatLng(
          days[2].customers[0].lat,
          days[2].customers[0].lng
        );
        const pointB = new L.LatLng(
          days[2].customers[index]?.lat,
          days[2].customers[index]?.lng
        );
        const pointList = [pointA, pointB];
        const firstpolyline = new L.polyline(pointList, {
          color: "#723900",
          weight: 3,
          opacity: 1,
          smoothFactor: 1,
        });
        firstpolyline.addTo(fgWednesday);
      }
      marker.bindPopup(`${index + 1} - ${c.name}`, {
        closeButton: true,
      });
      marker.addTo(fgWednesday);
    }
    layers.push(fgWednesday);

    const fgThursday = L.featureGroup();
    for (const [index, c] of days[3].customers.entries()) {
      L.NumberedDivIcon = L.Icon.extend({
        options: {
          iconUrl: Icons.RedMarker,
          number: "",
          shadowUrl: null,
          iconSize: new L.Point(50, 50),
          iconAnchor: new L.Point(13, 41),
          popupAnchor: new L.Point(0, -33),
          className: "leaflet-div-icon",
        },
        createIcon() {
          const div = document.createElement("div");
          const img = this._createImg(this.options.iconUrl);
          const numdiv = document.createElement("div");
          numdiv.setAttribute("class", "number");
          numdiv.innerHTML = this.options.number || "";
          img.setAttribute("class", "location-pin-img");
          div.appendChild(img);
          div.appendChild(numdiv);
          this._setIconStyles(div, "icon");
          return div;
        },
        createShadow() {
          return null;
        },
      });
      const markerOptions = {
        clickable: true,
        draggable: false,
        icon: new L.NumberedDivIcon({ number: index + 1 }),
      };
      const marker = L.marker([c.lat, c.lng], markerOptions);
      if (days[3].customers[index + 1]) {
        const pointA = new L.LatLng(c.lat, c.lng);
        const pointB = new L.LatLng(
          days[3].customers[index + 1]?.lat,
          days[3].customers[index + 1]?.lng
        );
        const pointList = [pointA, pointB];
        const firstpolyline = new L.polyline(pointList, {
          color: "#FF0000",
          weight: 3,
          opacity: 1,
          smoothFactor: 1,
        });
        firstpolyline.addTo(fgThursday);
      }
      if (!days[3].customers[index + 1]) {
        const pointA = new L.LatLng(
          days[3].customers[0].lat,
          days[3].customers[0].lng
        );
        const pointB = new L.LatLng(
          days[3].customers[index]?.lat,
          days[3].customers[index]?.lng
        );
        const pointList = [pointA, pointB];
        const firstpolyline = new L.polyline(pointList, {
          color: "#FF0000",
          weight: 3,
          opacity: 1,
          smoothFactor: 1,
        });
        firstpolyline.addTo(fgThursday);
      }
      marker.bindPopup(`${index + 1} - ${c.name}`, {
        closeButton: true,
      });
      marker.addTo(fgThursday);
    }
    layers.push(fgThursday);

    const fgFriday = L.featureGroup();
    for (const [index, c] of days[4].customers.entries()) {
      L.NumberedDivIcon = L.Icon.extend({
        options: {
          iconUrl: Icons.BlackMarker,
          number: "",
          shadowUrl: null,
          iconSize: new L.Point(50, 50),
          iconAnchor: new L.Point(13, 41),
          popupAnchor: new L.Point(0, -33),
          className: "leaflet-div-icon",
        },
        createIcon() {
          const div = document.createElement("div");
          const img = this._createImg(this.options.iconUrl);
          const numdiv = document.createElement("div");
          numdiv.setAttribute("class", "number");
          numdiv.innerHTML = this.options.number || "";
          img.setAttribute("class", "location-pin-img");
          div.appendChild(img);
          div.appendChild(numdiv);
          this._setIconStyles(div, "icon");
          return div;
        },
        createShadow() {
          return null;
        },
      });
      const markerOptions = {
        clickable: true,
        draggable: false,
        icon: new L.NumberedDivIcon({ number: index + 1 }),
      };
      const marker = L.marker([c.lat, c.lng], markerOptions);
      if (days[4].customers[index + 1]) {
        const pointA = new L.LatLng(c.lat, c.lng);
        const pointB = new L.LatLng(
          days[4].customers[index + 1]?.lat,
          days[4].customers[index + 1]?.lng
        );
        const pointList = [pointA, pointB];
        const firstpolyline = new L.polyline(pointList, {
          color: "#000",
          weight: 3,
          opacity: 1,
          smoothFactor: 1,
        });
        firstpolyline.addTo(fgFriday);
      }
      if (!days[4].customers[index + 1]) {
        const pointA = new L.LatLng(
          days[4].customers[0].lat,
          days[4].customers[0].lng
        );
        const pointB = new L.LatLng(
          days[4].customers[index]?.lat,
          days[4].customers[index]?.lng
        );
        const pointList = [pointA, pointB];
        const firstpolyline = new L.polyline(pointList, {
          color: "#000",
          weight: 3,
          opacity: 1,
          smoothFactor: 1,
        });
        firstpolyline.addTo(fgFriday);
      }
      marker.bindPopup(`${index + 1} - ${c.name}`, {
        closeButton: true,
      });
      marker.addTo(fgFriday);
    }
    layers.push(fgFriday);

    const fgSaturday = L.featureGroup();
    for (const [index, c] of days[5].customers.entries()) {
      L.NumberedDivIcon = L.Icon.extend({
        options: {
          iconUrl: Icons.PurpleMarkerr,
          number: "",
          shadowUrl: null,
          iconSize: new L.Point(50, 50),
          iconAnchor: new L.Point(13, 41),
          popupAnchor: new L.Point(0, -33),
          className: "leaflet-div-icon",
        },
        createIcon() {
          const div = document.createElement("div");
          const img = this._createImg(this.options.iconUrl);
          const numdiv = document.createElement("div");
          numdiv.setAttribute("class", "number");
          numdiv.innerHTML = this.options.number || "";
          img.setAttribute("class", "location-pin-img");
          div.appendChild(img);
          div.appendChild(numdiv);
          this._setIconStyles(div, "icon");
          return div;
        },
        createShadow() {
          return null;
        },
      });
      const markerOptions = {
        clickable: true,
        draggable: false,
        icon: new L.NumberedDivIcon({ number: index + 1 }),
      };
      const marker = L.marker([c.lat, c.lng], markerOptions);
      if (days[5].customers[index + 1]) {
        const pointA = new L.LatLng(c.lat, c.lng);
        const pointB = new L.LatLng(
          days[5].customers[index + 1]?.lat,
          days[5].customers[index + 1]?.lng
        );
        const pointList = [pointA, pointB];
        const firstpolyline = new L.polyline(pointList, {
          color: "#800080",
          weight: 3,
          opacity: 1,
          smoothFactor: 1,
        });
        firstpolyline.addTo(fgSaturday);
      }
      if (!days[5].customers[index + 1]) {
        const pointA = new L.LatLng(
          days[5].customers[0].lat,
          days[5].customers[0].lng
        );
        const pointB = new L.LatLng(
          days[5].customers[index]?.lat,
          days[5].customers[index]?.lng
        );
        const pointList = [pointA, pointB];
        const firstpolyline = new L.polyline(pointList, {
          color: "#800080",
          weight: 3,
          opacity: 1,
          smoothFactor: 1,
        });
        firstpolyline.addTo(fgSaturday);
      }

      marker.bindPopup(`${index + 1} - ${c.name}`, {
        closeButton: true,
      });
      marker.addTo(fgSaturday);
    }
    layers.push(fgSaturday);

    let index_counter = 0;
    for (const l of layers) {
      if (days[index_counter++].checked) mapState.addLayer(l);
    }
    setLayers(layers);
  }, [
    days[0].customers,
    days[1].customers,
    days[2].customers,
    days[3].customers,
    days[4].customers,
    days[5].customers,
    mapState,
    show,
  ]);

  useEffect(() => {
    if (mapState || !show) return;
    if (
      days[0].customers.length === 0 &&
      days[1].customers.length === 0 &&
      days[2].customers.length === 0 &&
      days[3].customers.length === 0 &&
      days[4].customers.length === 0 &&
      days[5].customers.length === 0
    ) {
      return;
    }

    const mapboxTiles = L.tileLayer(
      "http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    );
    if (!firstMapPoint) return;
    const map = L.map("day_map", {
      wheelPxPerZoomLevel: 150,
    })
      .addLayer(mapboxTiles)
      .setView([firstMapPoint.lat, firstMapPoint.lng], 11);
    setMapState(map);
  }, [
    show,
    firstMapPoint,
    mapState,
    days[0].customers,
    days[1].customers,
    days[2].customers,
    days[3].customers,
    days[4].customers,
    days[5].customers,
  ]);

  useEffect(() => {
    if (show) {
      var r = document.querySelector(":root");
      r.style.setProperty("--bs-modal-width", 800);
    } else {
      setMapState(null);
      setLayers(null);
      setDays([
        {
          id: 1,
          name: "Pazartesi",
          checked: true,
          customers: [],
          depo: null,
        },
        {
          id: 2,
          name: "Salı",
          checked: false,
          customers: [],
          depo: null,
        },
        {
          id: 3,
          name: "Çarşamba",
          checked: false,
          customers: [],
          depo: null,
        },
        {
          id: 4,
          name: "Perşembe",
          checked: false,
          customers: [],
          depo: null,
        },
        {
          id: 5,
          name: "Cuma",
          checked: false,
          customers: [],
          depo: null,
        },
        {
          id: 6,
          name: "Cumartesi",
          checked: false,
          customers: [],
          depo: null,
        },
      ]);
    }
  }, [show]);

  useEffect(() => {
    if (areaDayDragDropModal.open === false) return;
    if (areaDayDragDropModal.focusDayId == null) return;

    let state = areaDayDragDropModal;
    state.customers = days[state.focusDayId - 1].customers;
    console.log(days[state.focusDayId - 1].depo);
    state.depo = days[state.focusDayId - 1].depo;
    setAreaDayDragDropModal(state);
  }, [days]);
  const onHideDayMapCustomersModal = () => {
    setAreaDayDragDropModal({
      day: "",
      open: false,
      customers: [],
      focusDayId: null,
      depo: null,
    });
  };

  const onPenClick = (day) => {
    setAreaDayDragDropModal({
      day: day.name,
      open: true,
      customers: day.customers,
      focusDayId: day.id,
      depo: day.depo,
    });
  };

  const onRefactorDaysClick = () => {
    setAreaDaysDragDropModal({
      open: true,
    });
  };

  const onHideRefactorDaysClick = () => {
    setAreaDaysDragDropModal({
      open: false,
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        backdrop="static"
        dialogClassName="modal-1280"
      >
        <Modal.Header closeButton>
          <Modal.Title>{areaName} Bölgesi</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ position: "relative", height: 700 }}>
          <div id="day_map" />
          <div className="area-box">
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ marginTop: 20, marginBottom: 20, marginLeft: 10 }}
            >
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip>
                    {areaName} bölgesi içindeki müşterilerin ziyaret günlerini
                    değiştirmeye yarayan pencereyi açar
                  </Tooltip>
                }
              >
                <Button size="sm" onClick={onRefactorDaysClick}>
                  Ziyaret Günleri Değiştir
                </Button>
              </OverlayTrigger>
            </div>
            {days
              .filter((d) => d.customers.length)
              .map((d) => {
                return (
                  <div
                    className="d-flex justify-content-between align-items-center"
                    key={JSON.stringify(d)}
                  >
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        <Tooltip>
                          {d.name} günü ziyaret edilen müşterilerin sıralamasını
                          değiştirmeye yarayan pencereyi açar
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="secondary"
                        style={{ marginRight: 20, marginLeft: 10 }}
                        size="sm"
                        onClick={() => onPenClick(d)}
                      >
                        <i className="fa-regular fa-pen-to-square fa-sm"></i>
                      </Button>
                    </OverlayTrigger>
                    <div
                      key={JSON.stringify(d)}
                      className="d-flex justify-content-between align-items-center areaCheck"
                      onClick={() => {
                        handleCheck(d.id, d.checked);
                      }}
                      style={{ width: "80%" }}
                    >
                      <div style={{ marginRight: 5 }}>{d.name}</div>
                      <Form.Check
                        className="d-flex position-relative mb-1"
                        checked={d.checked}
                        value={true}
                        onChange={() => {}}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </Modal.Body>
      </Modal>
      <AreaDayDragDropModal
        open={areaDayDragDropModal.open}
        areaName={areaName}
        areaId={areaId}
        day={areaDayDragDropModal.day}
        customers={areaDayDragDropModal.customers}
        depo={areaDayDragDropModal.depo}
        onClose={onHideDayMapCustomersModal}
        onSuccess={getArea}
      />
      <AreaDaysDragDrop
        open={areaDaysDragDropModal.open}
        items={days}
        areaId={areaId}
        areaName={areaName}
        onClose={onHideRefactorDaysClick}
        onSuccess={getArea}
      />
    </>
  );
};

export default AreaDaysModal;
