import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";

const MoveCustomerModal = ({
  changeAreaModal,
  onCloseAreaModal,
  areas,
  territoryToChange,
  setTerritoryToChange,
  onChangeArea,
}) => {
  return (
    <Modal show={changeAreaModal.show} onHide={onCloseAreaModal}>
      <Modal.Header closeButton>
        <Modal.Title>Bölge Değiştir</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            {territoryToChange ? territoryToChange.name : "Bölge Seç"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(areas).map((i) => {
              return (
                <Dropdown.Item
                  key={JSON.stringify(i)}
                  onClick={() =>
                    setTerritoryToChange({
                      id: areas[i].id,
                      name: areas[i].name,
                    })
                  }
                >
                  {areas[i].name}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCloseAreaModal}>
          Hayır
        </Button>
        <Button
          variant="primary"
          onClick={() =>
            onChangeArea(territoryToChange.id, changeAreaModal.customer)
          }
        >
          Değiştir
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MoveCustomerModal;
