import "./index.scss";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Form, Button, Spinner, Row } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Table from "react-bootstrap/Table";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import InputGroup from "react-bootstrap/InputGroup";

import MoveCustomerModal from "./modals/MoveCustomerModal";
import ChangeAreaNameModal from "./modals/ChangeAreaNameModal";
import ChangeDepoModal from "./modals/ChangeDepoModal";
import AreaDaysModal from "./modals/area-days-map";
import ShiftCustomersBetweenAreasModal from "./modals/shift-customers-between-areas";
import YesNoModal from "./modals/YesNoModal";
import ParameterModal from "./modals/ParameterModal";
import AddNewAreaModal from "./modals/AddNewAreaModal";
import FileLoaderScreen from "../../components/file-loader-screen";

import useDashboard from "./useDashboard";
import AppUtils from "../../utils";
import AppConfig from "../../config";
import LockScreen from "../../components/lock-screen";
import Icons from "../../assets/icons";
import useNetworkRequests from "./useNetworkRequests";
import OperatinalCostsModal from "./modals/operational-costs";
import SwapCustomersBetweenDaysModal from "./modals/swap-customers-between-days";

const App = () => {
  const uploadRef = useRef();
  const mapRef = useRef();
  const navigate = useNavigate();
  const {
    map,
    areas,
    drawAreas,
    drawMap,
    changeAreaModal,
    onCloseAreaModal,
    listenSocketMessages,
    territoryToChange,
    setTerritoryToChange,
    onChangeArea,
    mapLoading,
    redrawAreas,
    lockScreen,
    utilizations,
    setUtilizations,
    displayUtilizations,
    setDisplayUtilizations,
    costChanges,
    projectFileLoadingProgress,
    setProjectFileLoadingProgress,
    posSearch,
    setPosSearch,
    customersInsidePool,
    onCustomerInsidePoolClick,
    onRemoveCustomerFromPoolClick,
    onPoolSaveClick,
    shiftCustomersBetweenAreasModal,
    setShiftCustomersBetweenAreasModal,
    isOperationalCostModalOpen,
    setIsOperationalCostModalOpen,
    downloadSampleFile,
    operatinoalCosts,
    onClickCalculateOperationalCosts,
    calculateLoading,
    isSwapCustomersBetweenDaysModalOpen,
    setIsSwapCustomersBetweenDaysModalOpen,
  } = useDashboard();

  const {
    sendMessageToDownloadExcel,
    intiateOptimization,
    requestRouteOptimization,
    requestDuplicateArea,
    requestOptimizationDaySwap,
    requestRouteReport,
    resetBack,
    deleteArea,
  } = useNetworkRequests();

  const [mapHeight, setMapHeight] = useState(0);
  const [areaToChange, setAreaToChange] = useState({ name: "", id: "" });
  const [depotToChange, setDepotToChange] = useState({
    name: "",
    id: "",
    areaId: "",
  });
  const [areaDaysModal, setAreaDaysModal] = useState({
    show: false,
    areaId: 0,
    areaName: "",
  });
  const [parameterModal, setParameterModal] = useState({
    show: false,
    areaId: 0,
    areaName: "",
  });
  const [deleteAreaModal, setDeleteAreaModal] = useState({
    show: false,
    areaId: 0,
    areaName: "",
  });
  const [addNewAreaModal, setAddNewAreaModal] = useState({
    show: false,
    areaId: 0,
    areaName: "",
  });

  useEffect(() => {
    drawAreas();
  }, [drawAreas]);

  useEffect(() => {
    drawMap();
  }, [drawMap]);

  useEffect(() => {
    listenSocketMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const dateBasedSortedUtilsIndices =
      argsortUtilsBasedOnActiveTime(utilizations);
    setDisplayUtilizations(dateBasedSortedUtilsIndices);
  }, [setDisplayUtilizations, utilizations]);

  useEffect(() => {
    if (mapRef.current) {
      setMapHeight(mapRef.current.offsetHeight);
    }
  }, [mapRef]);

  const argsortUtilsBasedOnActiveTime = (arr) => {
    const key = "activeTime";
    return arr
      .map((item, index) => [item[key], index])
      .filter(([value]) => value !== null && value !== undefined)
      .sort(([a], [b]) => a.getTime() - b.getTime())
      .map(([, index]) => index);
  };

  const handleXlsxUpload = async (e) => {
    try {
      const file = e.target.files[0];
      if (!file) {
        toast.error(
          "Sizin için hangi veri setiyle yardımcı olabiliriz? Lütfen seçin."
        );
        return;
      }
      const formData = new FormData();
      formData.append("file", file);

      await AppConfig.axiosInstances.MainAuth.post(`/projectfile`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      e.target.value = null;
    } catch (error) {
      toast.error(
        `Beklenmeyen bir hata oluştu, kod: 1, status: ${e.response?.status}`
      );
      e.target.value = null;
      setProjectFileLoadingProgress({
        progress: 0,
        message: "",
        isLoading: false,
      });
    }
  };

  const onClickDeletaArea = (id) => {
    setDeleteAreaModal({
      show: true,
      id,
      name: areas[id].name,
      title: `${areas[id].name} Bölgesini Sil`,
      text: `${areas[id].name} bölgesini silmek istediğinize emin misiniz?`,
    });
  };

  const onAreaClick = (item) => {
    const utilization = utilizations.find((a) => a.area?.id === item.id);
    if (!utilization) return;
    if (utilization.active) {
      setUtilizations((prevState) => {
        const prev = [...prevState];
        const newState = prev.map((a) => {
          if (a.area.id === item.id) {
            a.active = false;
            a.activeTime = null;
          }
          return a;
        });
        return newState;
      });
      return;
    }
    //update the state separately so that we go in sync, otherwise we can update ui when display state is not updated yet.
    const prev = [...utilizations];
    const newState = prev.map((a) => {
      if (a.area?.id === utilization.area.id) {
        a.active = true;
        a.activeTime = new Date();
      }
      return a;
    });
    //uncheck the oldest util if we exceed 3 places;
    let dateBasedSortedUtilsIndices = argsortUtilsBasedOnActiveTime(newState);
    if (dateBasedSortedUtilsIndices.length > 3) {
      newState[dateBasedSortedUtilsIndices[0]].active = false;
      newState[dateBasedSortedUtilsIndices[0]].activeTime = null;
    }
    //update and display
    setUtilizations(newState);
  };

  const onClickAddNewArea = () => {
    setAddNewAreaModal({
      show: true,
      title: "Yeni Bölge Ekle",
      text: "Bölge adını giriniz",
    });
  };

  const onExitClick = () => {
    sessionStorage.removeItem("token");
    navigate("/login");
  };

  const loading = utilizations.length === 0 || Object.keys(areas).length === 0;

  const [mapSize, setMapSize] = useState(35);

  useEffect(()=>{
    if(map === undefined) return;
    map.invalidateSize();
  }, [mapSize])

  return (
    <>
      <div className="app">
        <div className="left">
          <div className="totals">
            {loading ? (
              <div className="totals-loading">
                <Spinner animation="border" variant="success" />
              </div>
            ) : null}
            {Object.keys(areas).length > 0 && utilizations.length > 0 ? (
              <div className="w-100">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="totals_box">
                    <div className="totals_box_left">
                      <img
                        src={Icons.Pin}
                        alt="pin"
                        className="totals_box_icon"
                      />
                      <p>Toplam Call</p>
                    </div>
                    <div>
                      {utilizations
                        .map(
                          (u) =>
                            Number(u.cusomtersDay0) +
                            Number(u.cusomtersDay1) +
                            Number(u.cusomtersDay2) +
                            Number(u.cusomtersDay3) +
                            Number(u.cusomtersDay4) +
                            Number(u.cusomtersDay5)
                        )
                        .reduce((a, b) => a + b, 0)}
                    </div>
                  </div>
                  <div className="totals_box">
                    <div className="totals_box_left">
                      <img
                        src={Icons.Road}
                        alt="road"
                        className="totals_box_icon"
                      />
                      <p>Toplam Mesafe</p>
                    </div>
                    <div className="d-flex justify-content-center align-items-end flex-column">
                      {utilizations
                        .map((u) => Number(u.totalDistance.toFixed(2)))
                        .reduce((a, b) => a + b, 0)
                        .toFixed(0)}{" "}
                      km
                      {Number(costChanges.delta_distance.toFixed(2)) > 0 ? (
                        <div className="costchange-positive">
                          + {costChanges.delta_distance.toFixed(2)} km
                        </div>
                      ) : null}
                      {Number(costChanges.delta_distance.toFixed(2)) < 0 ? (
                        <div className="costchange-negative">
                          {costChanges.delta_distance.toFixed(2)} km
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="totals_box">
                    <div className="totals_box_left">
                      <img
                        src={Icons.Pos}
                        alt="pos"
                        className="totals_box_icon"
                      />
                      <p>Toplam Pos</p>
                    </div>
                    <div>
                      {utilizations
                        .map((u) => Number(u.uniqueCustomers.toFixed(2)))
                        .reduce((a, b) => a + b, 0)}
                    </div>
                  </div>
                  <div className="totals_box">
                    <div className="totals_box_left">
                      <img
                        src={Icons.Productivity}
                        alt="productivity"
                        className="totals_box_icon"
                      />
                      <p>Ort. Verimlilik</p>
                    </div>
                    <div className="d-flex justify-content-center align-items-end flex-column">
                      %
                      {(
                        utilizations
                          .map((u) => Number(u.averageUtilization * 100))
                          .reduce((a, b) => a + b) / utilizations.length
                      ).toFixed(0)}
                      {Number(costChanges.delta_utilization.toFixed(2)) > 0 ? (
                        <div className="costchange-positive">
                          + {costChanges.delta_utilization.toFixed(2)}
                        </div>
                      ) : null}
                      {Number(costChanges.delta_utilization.toFixed(2)) < 0 ? (
                        <div className="costchange-negative">
                          %{costChanges.delta_utilization.toFixed(2)}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center costs">
                  {Object.keys(operatinoalCosts)
                    .sort((a, b) => {
                      if (a === "fuel_cost") return -1;
                      if (b === "fuel_cost") return 1;
                      if (a === "worker_cost") return -1;
                      if (b === "worker_cost") return 1;
                      if (a === "depreciation_cost") return -1;
                      if (b === "depreciation_cost") return 1;
                      if (a === "co2_cost") return -1;
                      if (b === "co2_cost") return 1;
                      if (a === "overall_cost") return -1;
                      if (b === "overall_cost") return 1;
                      return 0;
                    })
                    .map((key) => {
                      return (
                        <div key={key}>
                          <b>
                            {key === "fuel_cost"
                              ? "Yakıt Maliyeti"
                              : key === "co2_cost"
                              ? "Karbon salınımı (kg)"
                              : key === "depreciation_cost"
                              ? "Araç Yıpranma Payı"
                              : key === "worker_cost"
                              ? "Personel Maliyeti"
                              : key === "overall_cost"
                              ? "Toplam Operasyon Maliyeti"
                              : "N/A"}
                          </b>
                          :{" "}
                          {Boolean(operatinoalCosts[key])
                            ? operatinoalCosts[key].toFixed(2)
                            : 0}
                        </div>
                      );
                    })}
                </div>
              </div>
            ) : null}
          </div>
          <div className="slide-container">
            <div>
              <Button
                variant="primary"
                onClick={onClickCalculateOperationalCosts}
                disabled={calculateLoading || loading}
                size="sm"
                style={{ fontSize: 12 }}
              >
                Maliyet Hesapla
                {!loading && calculateLoading ? (
                  <i
                    className="fa-solid fa-spinner fa-spin"
                    style={{ marginLeft: 4 }}
                  />
                ) : (
                  <i
                    className="fa-solid fa-calculator fa-xs"
                    style={{ marginLeft: 4 }}
                  ></i>
                )}
              </Button>
            </div>
            <Form.Group as={Row} className="mb-3">
              <div className="d-flex align-items-center ">
                <i
                  className="fa-solid fa-square-minus text-white hover-minus"
                  style={{ marginRight: 5, padding: 5, fontSize: 18 }}
                  onClick={(e) => {
                    if (mapSize <= 10) return;
                    setMapSize((prev) => prev - 5);
                  }}
                />
                <div
                  style={{
                    fontSize: 12,
                    width: "unset",
                    paddingRight: 2,
                    marginBottom: 2,
                  }}
                >
                  Harita Yüksekliği
                </div>
                <i
                  className="fa-solid fa-square-plus text-white hover-plus"
                  style={{ marginLeft: 5, padding: 5, fontSize: 18 }}
                  onClick={(e) => setMapSize((prev) => prev + 5)}
                />
              </div>
            </Form.Group>
          </div>
          <div className="map-wrapper" style={{ height: `${mapSize}%` }}>
            <div
              className="map-sidebar"
              style={{
                filter: `${
                  mapLoading ||
                  lockScreen ||
                  utilizations.length === 0 ||
                  Object.keys(areas).length === 0
                    ? "blur(2px)"
                    : ""
                }`,
              }}
            >
              <InputGroup size="sm" variant="success">
                <InputGroup.Text id="pos-search-input">POS Ara</InputGroup.Text>
                <Form.Control
                  aria-label="POS Ara"
                  aria-describedby="pos-search-input"
                  onChange={(e) => setPosSearch(e.target.value)}
                  value={posSearch}
                  disabled={
                    utilizations.length === 0 || Object.keys(areas).length === 0
                  }
                />
              </InputGroup>
              {Object.keys(customersInsidePool).length > 0 ? (
                <div
                  className="map-pool"
                  style={{
                    height: `calc(${mapHeight}px - 80px)`,
                  }}
                >
                  <div className="map-pool-title">Havuzdaki Müşteriler</div>
                  <div className="map-pool-list">
                    {Object.keys(customersInsidePool).map((c) => {
                      return (
                        <div
                          className="map-pool-customer"
                          key={JSON.stringify(customersInsidePool[c], null, 2)}
                        >
                          <div
                            onClick={() =>
                              onCustomerInsidePoolClick(customersInsidePool[c])
                            }
                          >
                            {customersInsidePool[c].name}
                          </div>
                          <Button
                            variant="danger"
                            size="sm"
                            style={{ fontSize: 10, padding: "2px 5px" }}
                            onClick={() =>
                              onRemoveCustomerFromPoolClick(
                                customersInsidePool[c]
                              )
                            }
                          >
                            Çıkar
                          </Button>
                        </div>
                      );
                    })}
                  </div>
                  <div className="map-pool-footer">
                    <Button
                      variant="success"
                      size="sm"
                      onClick={onPoolSaveClick}
                      style={{ padding: "2px 5px", width: 100 }}
                    >
                      Ata
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>
            <div
              id="map"
              ref={mapRef}
              style={{
                filter: `${
                  mapLoading ||
                  lockScreen ||
                  utilizations.length === 0 ||
                  Object.keys(areas).length === 0
                    ? "blur(2px)"
                    : ""
                }`,
              }}
            />
            {mapLoading ||
            utilizations.length === 0 ||
            Object.keys(areas).length === 0 ? (
              <div
                className="w-100 h-100 position-absolute"
                style={{
                  zIndex: 10,
                  opacity: 0.5,
                  backgroundColor: "black",
                  left: 0,
                  top: 0,
                }}
              />
            ) : null}
          </div>
          <div className="info">
            {displayUtilizations
              ? displayUtilizations
                  .map((u) => utilizations[u])
                  .map((u) => {
                    if (u === undefined) return null;
                    if (!u.active) return null;
                    return (
                      <div className="info-container" key={JSON.stringify(u)}>
                        <div className="info-title">{u.area.title} </div>
                        <div className="info-header">
                          <div className="info-header-item">
                            <div>Pos</div>
                            <div className="info-header-item-value">
                              {u.uniqueCustomers}
                            </div>
                          </div>
                          <div className="info-header-item">
                            <div>Ort. Mesai</div>
                            <div className="info-header-item-value">
                              {AppUtils.percentToReadable(u.averageUtilization)}
                            </div>
                          </div>
                          <div className="info-header-item">
                            <div>Call</div>
                            <div className="info-header-item-value">
                              {u.cusomtersDay0 +
                                u.cusomtersDay1 +
                                u.cusomtersDay2 +
                                u.cusomtersDay3 +
                                u.cusomtersDay4 +
                                u.cusomtersDay5}
                            </div>
                          </div>
                          <div className="info-header-item">
                            <div>Top. Mesafe</div>
                            <div className="info-header-item-value">
                              {u.totalDistance.toFixed(1)} km
                            </div>
                          </div>
                        </div>
                        <Table striped bordered hover responsive>
                          <thead>
                            <tr>
                              <th className="info-table-header-row">Gün</th>
                              <th className="info-table-header-row">Call</th>
                              <th className="info-table-header-row">Km</th>
                              <th className="info-table-header-row">
                                Kalma Zamanı
                              </th>
                              <th className="info-table-header-row">
                                Rut Sonu
                              </th>
                              <th className="info-table-header-row">Verim</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="info-table-first-row">
                                Pazartesi
                              </td>
                              <td>{u.cusomtersDay0}</td>
                              <td>{u.km0.toFixed(0)}</td>
                              <td>{AppUtils.timeToReadable(u.drivingTime0)}</td>
                              <td>{AppUtils.timeToReadable(u.finishTime0)}</td>
                              <td>
                                {AppUtils.percentToReadable(u.utilization0)}
                              </td>
                            </tr>
                            <tr>
                              <td className="info-table-first-row">Salı</td>
                              <td>{u.cusomtersDay1}</td>
                              <td>{u.km1.toFixed(0)}</td>
                              <td>{AppUtils.timeToReadable(u.drivingTime1)}</td>
                              <td>{AppUtils.timeToReadable(u.finishTime1)}</td>
                              <td>
                                {AppUtils.percentToReadable(u.utilization1)}
                              </td>
                            </tr>
                            <tr>
                              <td className="info-table-first-row">Çarşamba</td>
                              <td>{u.cusomtersDay2}</td>
                              <td>{u.km2.toFixed(0)}</td>
                              <td>{AppUtils.timeToReadable(u.drivingTime2)}</td>
                              <td>{AppUtils.timeToReadable(u.finishTime2)}</td>
                              <td>
                                {AppUtils.percentToReadable(u.utilization2)}
                              </td>
                            </tr>
                            <tr>
                              <td className="info-table-first-row">Perşembe</td>
                              <td>{u.cusomtersDay3}</td>
                              <td>{u.km3.toFixed(0)}</td>
                              <td>{AppUtils.timeToReadable(u.drivingTime3)}</td>
                              <td>{AppUtils.timeToReadable(u.finishTime3)}</td>
                              <td>
                                {AppUtils.percentToReadable(u.utilization3)}
                              </td>
                            </tr>
                            <tr>
                              <td className="info-table-first-row">Cuma</td>
                              <td>{u.cusomtersDay4}</td>
                              <td>{u.km4.toFixed(0)}</td>
                              <td>{AppUtils.timeToReadable(u.drivingTime4)}</td>
                              <td>{AppUtils.timeToReadable(u.finishTime4)}</td>
                              <td>
                                {AppUtils.percentToReadable(u.utilization4)}
                              </td>
                            </tr>
                            <tr>
                              <td className="info-table-first-row">
                                Cumartesi
                              </td>
                              <td>{u.cusomtersDay5}</td>
                              <td>{u.km5.toFixed(0)}</td>
                              <td>{AppUtils.timeToReadable(u.drivingTime5)}</td>
                              <td>{AppUtils.timeToReadable(u.finishTime5)}</td>
                              <td>
                                {AppUtils.percentToReadable(u.utilization5)}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    );
                  })
              : null}
          </div>
        </div>
        <div className="right">
          <input
            id="coordinateCsv"
            name="coordinateCsv"
            accept=".xlsx"
            type="file"
            ref={uploadRef}
            onChange={handleXlsxUpload}
          />
          <div className="right-header">
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                size="sm"
                style={{ marginLeft: 8 }}
                disabled={loading}
              >
                Aksiyonlar
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ fontSize: 13 }}>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip id="button-tooltip-2">
                      Yüklenilecek dosyanın formatını görmek için indiriniz.
                    </Tooltip>
                  }
                >
                  <Dropdown.Item onClick={() => downloadSampleFile()}>
                    Örnek Dosya İndir
                  </Dropdown.Item>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip id="button-tooltip-2">
                      Örnek dosya formatında simülasyon verilerini yükleyiniz.
                    </Tooltip>
                  }
                >
                  <Dropdown.Item onClick={() => uploadRef.current.click()}>
                    Excel Dosyası Yükle
                  </Dropdown.Item>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip id="button-tooltip-2">
                      Mevcut durumun rota planı raporunu indirir.
                    </Tooltip>
                  }
                >
                  <Dropdown.Item onClick={sendMessageToDownloadExcel}>
                    Sonuç Raporunu İndir
                  </Dropdown.Item>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip id="button-tooltip-2">
                      Sunucudan cevap gelmemesi durumunda kullanınız.
                    </Tooltip>
                  }
                >
                  <Dropdown.Item onClick={resetBack}>
                    Sunucuyu Yenile
                  </Dropdown.Item>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip id="button-tooltip-2">
                      Operasyon maliyetini görmek için gerekli bilgileri girin.
                    </Tooltip>
                  }
                >
                  <Dropdown.Item
                    onClick={() => {
                      setIsOperationalCostModalOpen(true);
                    }}
                  >
                    Maliyet Parametreleri Gir
                  </Dropdown.Item>
                </OverlayTrigger>
                <Dropdown.Divider />
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip id="button-tooltip-2">
                      Her bir bölge için gün içi sıralamaları optimize eder.
                    </Tooltip>
                  }
                >
                  <Dropdown.Item onClick={intiateOptimization}>
                    <b>Sıra Optimizasyonu</b>
                  </Dropdown.Item>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip id="button-tooltip-2">
                      Müşterileri atalı oldukları bölge içerisinde farklı
                      günlerde optimize ederek minimum mesafeye ulaşır.
                    </Tooltip>
                  }
                >
                  <Dropdown.Item
                    onClick={() => {
                      setIsSwapCustomersBetweenDaysModalOpen(true);
                    }}
                  >
                    <b>Gün Optimizasyonu</b>
                  </Dropdown.Item>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip id="button-tooltip-2">
                      Mevcut bölgeleri birbirleri arasında optimize etmek için
                      tıklayın.
                    </Tooltip>
                  }
                >
                  <Dropdown.Item
                    onClick={() => {
                      setShiftCustomersBetweenAreasModal({
                        ...shiftCustomersBetweenAreasModal,
                        show: true,
                      });
                    }}
                  >
                    <b>Bölge Optimizasyonu</b>
                  </Dropdown.Item>
                </OverlayTrigger>

                <Dropdown.Divider />
                <Dropdown.Item
                  onClick={onClickAddNewArea}
                  className="text-success"
                >
                  Yeni Bölge Ekle
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Button
              size="sm"
              variant="outline-danger"
              onClick={onExitClick}
              className="exit-button"
            >
              Çıkış Yap
            </Button>
          </div>
          <div className="right-content">
            <h1 className="right-title">BÖLGELER</h1>
            <div className="right-list">
              {Object.keys(areas).length === 0 ? (
                <div className="right-list-loading">
                  <Spinner animation="border" variant="success" />
                </div>
              ) : null}
              {Object.keys(areas).map((i) => {
                const utilization = utilizations.find((a) => a.area?.id === i);
                return (
                  <div key={JSON.stringify(i)} className="right-list-item">
                    <div className="d-flex justify-content-between align-items-end">
                      <Form.Check
                        type="checkbox"
                        label={areas[i].name}
                        size="sm"
                        onClick={() => onAreaClick(areas[i])}
                        checked={utilization?.active}
                        onChange={() => {}}
                        style={{ marginRight: 5 }}
                      />
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      <Dropdown>
                        <Dropdown.Toggle variant="primary" size="sm">
                          Ayarlar
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{ fontSize: 13 }}>
                          <Dropdown.Item
                            size="sm"
                            onClick={() => {
                              setParameterModal({
                                show: true,
                                name: areas[i].name,
                                areaId: areas[i].id,
                              });
                            }}
                          >
                            Parametre Değiştir
                          </Dropdown.Item>
                          <Dropdown.Item
                            size="sm"
                            onClick={() => {
                              setDepotToChange({
                                name: areas[i].depo
                                  ? areas[i].depo?.customer_name
                                  : "N/A",
                                id: areas[i].depo?.id,
                                areaId: areas[i].id,
                              });
                            }}
                          >
                            Depo Değiştir
                          </Dropdown.Item>
                          <Dropdown.Item
                            size="sm"
                            onClick={() => requestDuplicateArea(areas[i].id)}
                          >
                            Kopyala
                          </Dropdown.Item>
                          <Dropdown.Item
                            size="sm"
                            onClick={() =>
                              requestRouteOptimization(areas[i].id)
                            }
                          >
                            Sadece Bu Bölgeyi Optimize Et
                          </Dropdown.Item>
                          <Dropdown.Item
                            size="sm"
                            onClick={() =>
                              requestOptimizationDaySwap(areas[i].id)
                            }
                          >
                            Gün Kaydırma Çalıştır
                          </Dropdown.Item>
                          <Dropdown.Item
                            size="sm"
                            onClick={() =>
                              setAreaDaysModal({
                                show: true,
                                areaId: areas[i].id,
                                areaName: areas[i].name,
                              })
                            }
                          >
                            Ziyaret Günleri Haritasını Aç
                          </Dropdown.Item>
                          <Dropdown.Item
                            size="sm"
                            onClick={() => requestRouteReport(areas[i].id)}
                          >
                            Detay İndir
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item
                            size="sm"
                            onClick={() => onClickDeletaArea(areas[i].id)}
                            className="text-danger"
                          >
                            Bölgeyi Sil
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <MoveCustomerModal
          changeAreaModal={changeAreaModal}
          onCloseAreaModal={onCloseAreaModal}
          areas={areas}
          territoryToChange={territoryToChange}
          setTerritoryToChange={setTerritoryToChange}
          onChangeArea={onChangeArea}
        />
        <ChangeAreaNameModal
          item={areaToChange}
          onClose={() => setAreaToChange({ name: "", id: "" })}
          onSuccess={redrawAreas}
        />
        <ChangeDepoModal
          item={depotToChange}
          onClose={() => setDepotToChange({ name: "", id: "" })}
          onSuccess={redrawAreas}
        />
        <AreaDaysModal
          show={areaDaysModal.show}
          onClose={() =>
            setAreaDaysModal({ show: false, areaId: "", areaName: "" })
          }
          areaId={areaDaysModal.areaId}
          areaName={areaDaysModal.areaName}
        />
        <ShiftCustomersBetweenAreasModal
          areas={areas}
          state={shiftCustomersBetweenAreasModal}
          setState={setShiftCustomersBetweenAreasModal}
        />
        <SwapCustomersBetweenDaysModal
          show={isSwapCustomersBetweenDaysModalOpen}
          areas={areas}
          onClose={() => setIsSwapCustomersBetweenDaysModalOpen(false)}
        />
        <OperatinalCostsModal
          show={isOperationalCostModalOpen}
          onHide={() => setIsOperationalCostModalOpen(false)}
          onClickCta={onClickCalculateOperationalCosts}
        />
        <ParameterModal
          item={parameterModal}
          onClose={() =>
            setParameterModal({
              show: false,
              areaId: undefined,
              areaName: undefined,
            })
          }
          onSuccess={redrawAreas}
        />
        <YesNoModal
          title={deleteAreaModal.title}
          text={deleteAreaModal.text}
          onNo={() =>
            setDeleteAreaModal({ show: false, areaId: 0, title: "", text: "" })
          }
          onYes={() => {
            deleteArea(deleteAreaModal.id);
            setDeleteAreaModal({ show: false, areaId: 0, title: "", text: "" });
          }}
          show={deleteAreaModal.show}
        />
        <AddNewAreaModal
          item={addNewAreaModal}
          onClose={() =>
            setAddNewAreaModal({ show: false, areaId: 0, title: "", text: "" })
          }
        />
        <LockScreen isLoding={lockScreen || mapLoading}></LockScreen>
      </div>
      <FileLoaderScreen
        projectFileLoadingProgress={projectFileLoadingProgress}
      />
    </>
  );
};

export default App;
