import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import { toast } from "react-toastify";
import _ from "lodash";
import AppConfig from "../../../config";

const ChangeDepoModal = ({ item, onClose, onSuccess }) => {
  const [loading, setLoading] = useState("");
  const [depoId, setDepoId] = useState("");
  const [depos, setDepos] = useState([]);

  const getDepots = async () => {
    try {
      const response = await AppConfig.axiosInstances.MainAuth.get(`/depos`);
      setDepos(response.data);
    } catch (error) {
      toast.error("Depoları getirmede hata oluştu");
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const toastId = toast.loading("Depo değiştiriliyor...");
    try {
      setLoading(true);
      await AppConfig.axiosInstances.MainAuth.patch(
        `/areas/${item.areaId}/depo`,
        {
          depo: depoId,
        }
      );
      await onSuccess();
      onClose();
      toast.update(toastId, {
        render: "Depo değiştirildi",
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
        isLoading: false,
      });
      onClose();
      setDepoId("");
      getDepots();
    } catch (error) {
      toast.update(toastId, {
        render: "Bir hata oluştu, kod: 4",
        type: toast.TYPE.ERROR,
        autoClose: 3000,
        isLoading: false,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDepots();
  }, []);

  return (
    <Modal show={!!item.name} onHide={!loading ? onClose : () => {}}>
      <Modal.Header closeButton>
        <Modal.Title>{_.upperFirst(item.name)}Depo Değiştir</Modal.Title>
      </Modal.Header>
      <form onSubmit={onSubmit}>
        <Modal.Body>
          <Form.Select
            onChange={(e) => {
              setDepoId(e.target.value);
            }}
          >
            <option>Depo seçiniz</option>
            {depos.map((i) => {
              return (
                <option value={i.id} key={JSON.stringify(i)}>
                  {i.customer_name}
                </option>
              );
            })}
          </Form.Select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" type="submit">
            Değiştir
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ChangeDepoModal;
