import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";

import { toast } from "react-toastify";
import _ from "lodash";
import AppConfig from "../../../config";

const ChangeAreaNameModal = ({ item, onClose, onSuccess }) => {
  const [loading, setLoading] = useState("");
  const [name, setName] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    const toastId = toast.loading("Bölge adı değiştiriliyor...");
    try {
      setLoading(true);
      await AppConfig.axiosInstances.MainAuth.patch(`/areas/${item.id}`, {
        title: name,
      });
      await onSuccess();
      onClose();
      toast.update(toastId, {
        render: "Bölge adı değiştirildi",
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
        isLoading: false,
      });
      onClose();
      setName("");
    } catch (error) {
      toast.update(toastId, {
        render: "Bir hata oluştu, kod: 3",
        type: toast.TYPE.ERROR,
        autoClose: 3000,
        isLoading: false,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={!!item.name} onHide={!loading ? onClose : () => {}}>
      <Modal.Header closeButton>
        <Modal.Title>
          {_.upperFirst(item.name)} Bölge Adını Değiştir
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={onSubmit}>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Yeni alan adı"
              aria-label="Yeni alan adı"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Button variant="outline-secondary" type="submit">
              Değiştir
            </Button>
          </InputGroup>
        </form>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default ChangeAreaNameModal;
