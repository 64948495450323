import { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import { toast } from "react-toastify";
import _ from "lodash";
import AppConfig from "../../../config";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";

const ParameterModal = ({ item, onClose, onSuccess }) => {
  const [loading, setLoading] = useState("");
  const [ctaLoading, setCtaLoading] = useState("");

  const [title, setTitle] = useState("");
  const [serviceStartTime, setServiceStartTime] = useState("");
  const [serviceFinishTime, setServiceFinishTime] = useState("");
  const [serviceTime, setServiceTime] = useState("");

  const [limitWeekDay0, setLimitWeekDay0] = useState(0);
  const [serviceStartTimeDay0, setServiceStartTimeDay0] = useState("");
  const [serviceFinishTimeDay0, setServiceEndTimeDay0] = useState("");

  const [limitWeekDay1, setLimitWeekDay1] = useState(0);
  const [serviceStartTimeDay1, setServiceStartTimeDay1] = useState("");
  const [serviceFinishTimeDay1, setServiceEndTimeDay1] = useState("");

  const [limitWeekDay2, setLimitWeekDay2] = useState(0);
  const [serviceStartTimeDay2, setServiceStartTimeDay2] = useState("");
  const [serviceFinishTimeDay2, setServiceEndTimeDay2] = useState("");

  const [limitWeekDay3, setLimitWeekDay3] = useState(0);
  const [serviceStartTimeDay3, setServiceStartTimeDay3] = useState("");
  const [serviceFinishTimeDay3, setServiceEndTimeDay3] = useState("");

  const [limitWeekDay4, setLimitWeekDay4] = useState(0);
  const [serviceStartTimeDay4, setServiceStartTimeDay4] = useState("");
  const [serviceFinishTimeDay4, setServiceEndTimeDay4] = useState("");

  const [limitWeekDay5, setLimitWeekDay5] = useState(0);
  const [serviceStartTimeDay5, setServiceStartTimeDay5] = useState("");
  const [serviceFinishTimeDay5, setServiceEndTimeDay5] = useState("");

  const [enableFeasibilityCheck, setEnableFeasibilityCheck] = useState(false);

  const [applyToAll, setApplyToAll] = useState(false);

  const getItems = useCallback(async () => {
    try {
      const res = await AppConfig.axiosInstances.MainAuth.get(
        `/areas/${item.areaId}`
      );
      const data = res.data?.area;
      setTitle(data.title);
      setServiceStartTime(data.serviceStartTime);
      setServiceFinishTime(data.serviceFinishTime);
      setServiceTime(data.serviceTime);
      setEnableFeasibilityCheck(data.enableFeasibilityCheck);

      setLimitWeekDay0(data.limitWeekDay0);
      setServiceStartTimeDay0(
        data.serviceStartTimeDay0 ? data.serviceStartTimeDay0 : ""
      );
      setServiceEndTimeDay0(
        data.serviceFinishTimeDay0 ? data.serviceFinishTimeDay0 : ""
      );

      setLimitWeekDay1(data.limitWeekDay1);
      setServiceStartTimeDay1(
        data.serviceStartTimeDay1 ? data.serviceStartTimeDay1 : ""
      );
      setServiceEndTimeDay1(data.serviceFinishTimeDay1);

      setLimitWeekDay2(data.limitWeekDay2);
      setServiceStartTimeDay2(
        data.serviceStartTimeDay2 ? data.serviceStartTimeDay2 : ""
      );
      setServiceEndTimeDay2(
        data.serviceFinishTimeDay2 ? data.serviceFinishTimeDay2 : ""
      );

      setLimitWeekDay3(data.limitWeekDay3);
      setServiceStartTimeDay3(
        data.serviceStartTimeDay3 ? data.serviceStartTimeDay3 : ""
      );
      setServiceEndTimeDay3(
        data.serviceFinishTimeDay3 ? data.serviceFinishTimeDay3 : ""
      );

      setLimitWeekDay4(data.limitWeekDay4);
      setServiceStartTimeDay4(
        data.serviceStartTimeDay4 ? data.serviceStartTimeDay4 : ""
      );
      setServiceEndTimeDay4(
        data.serviceFinishTimeDay4 ? data.serviceFinishTimeDay4 : ""
      );

      setLimitWeekDay5(data.limitWeekDay5);
      setServiceStartTimeDay5(
        data.serviceStartTimeDay5 ? data.serviceStartTimeDay5 : ""
      );
      setServiceEndTimeDay5(
        data.serviceFinishTimeDay5 ? data.serviceFinishTimeDay5 : ""
      );
    } catch (error) {
      toast.error("Parametreleri getirmede hata oluştu");
    }
  }, [item.areaId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    const toastId = toast.loading("Parametreler değiştiriliyor...");
    try {
      setLoading(true);
      setCtaLoading(true);
      await AppConfig.axiosInstances.MainAuth.patch(`/areas/${item.areaId}`, {
        title,
        serviceStartTime: serviceStartTime ? serviceStartTime : "-1:00",
        serviceFinishTime: serviceFinishTime ? serviceFinishTime : "-1:00",
        serviceTime,
        enableFeasibilityCheck,
        limitWeekDay0,
        limitWeekDay1,
        limitWeekDay2,
        limitWeekDay3,
        limitWeekDay4,
        limitWeekDay5,
        serviceStartTimeDay0: serviceStartTimeDay0
          ? serviceStartTimeDay0
          : "-1:00",
        serviceStartTimeDay1: serviceStartTimeDay1
          ? serviceStartTimeDay1
          : "-1:00",
        serviceStartTimeDay2: serviceStartTimeDay2
          ? serviceStartTimeDay2
          : "-1:00",
        serviceStartTimeDay3: serviceStartTimeDay3
          ? serviceStartTimeDay3
          : "-1:00",
        serviceStartTimeDay4: serviceStartTimeDay4
          ? serviceStartTimeDay4
          : "-1:00",
        serviceStartTimeDay5: serviceStartTimeDay5
          ? serviceStartTimeDay5
          : "-1:00",
        serviceFinishTimeDay0: serviceFinishTimeDay0
          ? serviceFinishTimeDay0
          : "-1:00",
        serviceFinishTimeDay1: serviceFinishTimeDay1
          ? serviceFinishTimeDay1
          : "-1:00",
        serviceFinishTimeDay2: serviceFinishTimeDay2
          ? serviceFinishTimeDay2
          : "-1:00",
        serviceFinishTimeDay3: serviceFinishTimeDay3
          ? serviceFinishTimeDay3
          : "-1:00",
        serviceFinishTimeDay4: serviceFinishTimeDay4
          ? serviceFinishTimeDay4
          : "-1:00",
        serviceFinishTimeDay5: serviceFinishTimeDay5
          ? serviceFinishTimeDay5
          : "-1:00",
        applyToAll,
      });
      await onSuccess();
      onClose();
      toast.update(toastId, {
        render: "Parametreler değiştirildi",
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
        isLoading: false,
      });
    } catch (error) {
      toast.update(toastId, {
        render: "Bir hata oluştu, kod: 5",
        type: toast.TYPE.ERROR,
        autoClose: 3000,
        isLoading: false,
      });
    } finally {
      setLoading(false);
      setCtaLoading(false);
    }
  };

  useEffect(() => {
    if (item.show) {
      getItems();
    }
  }, [getItems, item.show]);

  return (
    <Modal show={item.show} onHide={!loading ? onClose : () => {}}>
      <Modal.Header closeButton>
        <Modal.Title>{_.upperFirst(item.name)} Bölge Parametreleri</Modal.Title>
      </Modal.Header>
      <form onSubmit={onSubmit}>
        <Modal.Body>
          <Row>
            <Col md={3}>
              <Form.Group
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Form.Label style={{ fontSize: 14 }} size="sm">
                  Bölge <br />
                  Adı
                </Form.Label>
                <Form.Control
                  type="text"
                  disabled={!title}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  size="sm"
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                className="mb-3"
              >
                <Form.Label style={{ fontSize: 14 }}>
                  Max Servis Süresi
                </Form.Label>
                <Form.Control
                  type="number"
                  disabled={loading}
                  value={serviceTime}
                  onChange={(e) => setServiceTime(e.target.value)}
                  size="sm"
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                className="mb-3"
              >
                <Form.Label style={{ fontSize: 14 }} size="sm">
                  Mesai Başlangıç Saati
                </Form.Label>
                <Form.Control
                  data-val="true"
                  data-val-regex="Geçersiz Saat."
                  data-val-regex-pattern="^(0[1-9]|1[0-2]):[0-5][0-9] (am|pm|AM|PM)$"
                  type="time"
                  disabled={loading}
                  value={serviceStartTime}
                  onChange={(e) => setServiceStartTime(e.target.value)}
                  size="sm"
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                className="mb-3"
              >
                <Form.Label style={{ fontSize: 14 }}>
                  Mesai Bitiş Saati{" "}
                </Form.Label>
                <Form.Control
                  disabled={loading}
                  value={serviceFinishTime}
                  data-val="true"
                  data-val-regex="Geçersiz Saat."
                  data-val-regex-pattern="^(0[1-9]|1[0-2]):[0-5][0-9] (am|pm|AM|PM)$"
                  type="time"
                  onChange={(e) => setServiceFinishTime(e.target.value)}
                  size="sm"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row></Row>
          <hr style={{ margin: "20px 0" }} />
          <Row>
            <Col md={4}>
              <Form.Group
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                className="mb-3"
              >
                <Form.Label style={{ fontSize: 14 }}>
                  Pazartesi Max Musteri Saysi
                </Form.Label>
                <Form.Control
                  type="text"
                  disabled={loading}
                  value={limitWeekDay0}
                  onChange={(e) => setLimitWeekDay0(Number(e.target.value))}
                  size="sm"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group
                style={{
                  display: "flex",

                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                className="mb-3"
              >
                <Form.Label style={{ fontSize: 14 }}>
                  Pazartesi Ziyaret Başlangıç Saati
                </Form.Label>
                <Form.Control
                  disabled={loading}
                  value={serviceStartTimeDay0}
                  data-val="true"
                  data-val-regex="Geçersiz Saat."
                  data-val-regex-pattern="^(0[1-9]|1[0-2]):[0-5][0-9] (am|pm|AM|PM)$"
                  type="time"
                  onChange={(e) => {
                    setServiceStartTimeDay0(e.target.value);
                  }}
                  size="sm"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                className="mb-3"
              >
                <Form.Label style={{ fontSize: 14 }}>
                  Pazartesi Ziyaret Bitiş Saati
                </Form.Label>
                <Form.Control
                  data-val="true"
                  data-val-regex="Geçersiz Saat."
                  data-val-regex-pattern="^(0[1-9]|1[0-2]):[0-5][0-9] (am|pm|AM|PM)$"
                  type="time"
                  disabled={loading}
                  value={serviceFinishTimeDay0}
                  onChange={(e) => setServiceEndTimeDay0(e.target.value)}
                  size="sm"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group
                style={{
                  display: "flex",

                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                className="mb-3"
              >
                <Form.Label style={{ fontSize: 14 }}>
                  Salı Max Musteri Saysi
                </Form.Label>
                <Form.Control
                  type="text"
                  disabled={loading}
                  value={limitWeekDay1}
                  onChange={(e) => setLimitWeekDay1(Number(e.target.value))}
                  size="sm"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group
                style={{
                  display: "flex",

                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                className="mb-3"
              >
                <Form.Label style={{ fontSize: 14 }}>
                  Salı Ziyaret Başlangıç Saati
                </Form.Label>
                <Form.Control
                  data-val="true"
                  data-val-regex="Geçersiz Saat."
                  data-val-regex-pattern="^(0[1-9]|1[0-2]):[0-5][0-9] (am|pm|AM|PM)$"
                  type="time"
                  disabled={loading}
                  value={serviceStartTimeDay1}
                  onChange={(e) => setServiceStartTimeDay1(e.target.value)}
                  size="sm"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                className="mb-3"
              >
                <Form.Label style={{ fontSize: 14 }}>
                  Salı Ziyaret Bitiş <br />
                  Saati
                </Form.Label>
                <Form.Control
                  data-val="true"
                  data-val-regex="Geçersiz Saat."
                  data-val-regex-pattern="^(0[1-9]|1[0-2]):[0-5][0-9] (am|pm|AM|PM)$"
                  type="time"
                  disabled={loading}
                  value={serviceFinishTimeDay1}
                  onChange={(e) => setServiceEndTimeDay1(e.target.value)}
                  size="sm"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Form.Group
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                className="mb-3"
              >
                <Form.Label style={{ fontSize: 14 }}>
                  Çarşamba Max Musteri Saysi
                </Form.Label>
                <Form.Control
                  type="text"
                  disabled={loading}
                  value={limitWeekDay2}
                  onChange={(e) => setLimitWeekDay2(Number(e.target.value))}
                  size="sm"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group
                style={{
                  display: "flex",

                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                className="mb-3"
              >
                <Form.Label style={{ fontSize: 14 }}>
                  Çarşamba Ziyaret Başlangıç Saati
                </Form.Label>
                <Form.Control
                  data-val="true"
                  data-val-regex="Geçersiz Saat."
                  data-val-regex-pattern="^(0[1-9]|1[0-2]):[0-5][0-9] (am|pm|AM|PM)$"
                  type="time"
                  disabled={loading}
                  value={serviceStartTimeDay2}
                  onChange={(e) => setServiceStartTimeDay2(e.target.value)}
                  size="sm"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group
                style={{
                  display: "flex",

                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                className="mb-3"
              >
                <Form.Label style={{ fontSize: 14 }}>
                  Çarşamba Ziyaret Bitiş Saati
                </Form.Label>
                <Form.Control
                  data-val="true"
                  data-val-regex="Geçersiz Saat."
                  data-val-regex-pattern="^(0[1-9]|1[0-2]):[0-5][0-9] (am|pm|AM|PM)$"
                  type="time"
                  disabled={loading}
                  value={serviceFinishTimeDay2}
                  onChange={(e) => setServiceEndTimeDay2(e.target.value)}
                  size="sm"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Form.Group
                style={{
                  display: "flex",

                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                className="mb-3"
              >
                <Form.Label style={{ fontSize: 14 }}>
                  Perşembe Max Musteri Saysi
                </Form.Label>
                <Form.Control
                  type="text"
                  disabled={loading}
                  value={limitWeekDay3}
                  onChange={(e) => setLimitWeekDay3(Number(e.target.value))}
                  size="sm"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group
                style={{
                  display: "flex",

                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                className="mb-3"
              >
                <Form.Label style={{ fontSize: 14 }}>
                  Perşembe Ziyaret Başlangıç Saati
                </Form.Label>
                <Form.Control
                  data-val="true"
                  data-val-regex="Geçersiz Saat."
                  data-val-regex-pattern="^(0[1-9]|1[0-2]):[0-5][0-9] (am|pm|AM|PM)$"
                  type="time"
                  disabled={loading}
                  value={serviceStartTimeDay3}
                  onChange={(e) => setServiceStartTimeDay3(e.target.value)}
                  size="sm"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group
                style={{
                  display: "flex",

                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                className="mb-3"
              >
                <Form.Label style={{ fontSize: 14 }}>
                  Perşembe Ziyaret Bitiş Saati
                </Form.Label>
                <Form.Control
                  data-val="true"
                  data-val-regex="Geçersiz Saat."
                  data-val-regex-pattern="^(0[1-9]|1[0-2]):[0-5][0-9] (am|pm|AM|PM)$"
                  type="time"
                  disabled={loading}
                  value={serviceFinishTimeDay3}
                  onChange={(e) => setServiceEndTimeDay3(e.target.value)}
                  size="sm"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Form.Group
                style={{
                  display: "flex",

                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                className="mb-3"
              >
                <Form.Label style={{ fontSize: 14 }}>
                  Cuma Max Musteri Saysi
                </Form.Label>
                <Form.Control
                  type="text"
                  disabled={loading}
                  value={limitWeekDay4}
                  onChange={(e) => setLimitWeekDay4(Number(e.target.value))}
                  size="sm"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group
                style={{
                  display: "flex",

                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                className="mb-3"
              >
                <Form.Label style={{ fontSize: 14 }}>
                  Cuma Ziyaret Başlangıç Saati
                </Form.Label>
                <Form.Control
                  data-val="true"
                  data-val-regex="Geçersiz Saat."
                  data-val-regex-pattern="^(0[1-9]|1[0-2]):[0-5][0-9] (am|pm|AM|PM)$"
                  type="time"
                  disabled={loading}
                  value={serviceStartTimeDay4}
                  onChange={(e) => setServiceStartTimeDay4(e.target.value)}
                  size="sm"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group
                style={{
                  display: "flex",

                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                className="mb-3"
              >
                <Form.Label style={{ fontSize: 14 }}>
                  Cuma Ziyaret Bitiş Saati
                </Form.Label>
                <Form.Control
                  data-val="true"
                  data-val-regex="Geçersiz Saat."
                  data-val-regex-pattern="^(0[1-9]|1[0-2]):[0-5][0-9] (am|pm|AM|PM)$"
                  type="time"
                  disabled={loading}
                  value={serviceFinishTimeDay4}
                  onChange={(e) => setServiceEndTimeDay4(e.target.value)}
                  size="sm"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group
                style={{
                  display: "flex",

                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                className="mb-3"
              >
                <Form.Label style={{ fontSize: 14 }}>
                  Cumartesi Max Musteri Saysi
                </Form.Label>
                <Form.Control
                  type="text"
                  disabled={loading}
                  value={limitWeekDay5}
                  onChange={(e) => setLimitWeekDay5(Number(e.target.value))}
                  size="sm"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group
                style={{
                  display: "flex",

                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                className="mb-3"
              >
                <Form.Label style={{ fontSize: 14 }}>
                  Cumartesi Ziyaret Başlangıç Saati
                </Form.Label>
                <Form.Control
                  data-val="true"
                  data-val-regex="Geçersiz Saat."
                  data-val-regex-pattern="^(0[1-9]|1[0-2]):[0-5][0-9] (am|pm|AM|PM)$"
                  type="time"
                  disabled={loading}
                  value={serviceStartTimeDay5}
                  onChange={(e) => setServiceStartTimeDay5(e.target.value)}
                  size="sm"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group
                style={{
                  display: "flex",

                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                className="mb-3"
              >
                <Form.Label style={{ fontSize: 14 }}>
                  Cumartesi Ziyaret Bitiş Saati
                </Form.Label>
                <Form.Control
                  data-val="true"
                  data-val-regex="Geçersiz Saat."
                  data-val-regex-pattern="^(0[1-9]|1[0-2]):[0-5][0-9] (am|pm|AM|PM)$"
                  type="time"
                  disabled={loading}
                  value={serviceFinishTimeDay5}
                  onChange={(e) => setServiceEndTimeDay5(e.target.value)}
                  size="sm"
                />
              </Form.Group>
            </Col>
          </Row>
          <hr style={{ margin: "20px 0" }} />
          <Row>
            <Col md={6}>
              <Form.Group
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                className="mb-3"
              >
                <Form.Label style={{ fontSize: 14 }} size="sm">
                  Ayarları Bütün Bölgelere Uygula
                </Form.Label>
                <Form.Check
                  type="checkbox"
                  label=""
                  checked={applyToAll}
                  onChange={(e) => setApplyToAll(e.target.checked)}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                className="mb-3"
              >
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="button-tooltip-2">
                      Girilen arametreler ile çözmek için
                    </Tooltip>
                  }
                >
                  <Form.Label style={{ fontSize: 14 }} size="sm">
                    Feasible Çözüm
                  </Form.Label>
                </OverlayTrigger>
                <Form.Check
                  type="checkbox"
                  label=""
                  checked={enableFeasibilityCheck}
                  onChange={(e) => setEnableFeasibilityCheck(e.target.checked)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit">
            {ctaLoading ? (
              <>
                <i className="fa-solid fa-spinner fa-spin"></i>
                Değiştiriliyor...
              </>
            ) : (
              "Değiştir"
            )}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ParameterModal;
